import { useRef, useState, useMemo, useEffect } from "react";
import { formatAddress } from "../../../../../libs/helpers/formatAddress";
import ArrowIcon from "../../../../icons/ArrowIcon";

export default function PickupPointSearch({
  pickupPoints,
  value,
  onChange
}: any) {
  const [show, setShow] = useState(false);
  const ref: any = useRef(null);

  useEffect(() => {
    function handleClick(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    if (show) document.addEventListener("mousedown", handleClick);
    return () => {
      if (show) document.removeEventListener("mousedown", handleClick);
    };
  }, [show, setShow, ref]);

  const pointName = useMemo(() => {
    return value
      ? formatAddress(
          pickupPoints.find((p: any) => p.id === value),
          ["city"]
        )
      : null;
  }, [value]);

  return (
    <div className="relative w-full" ref={ref} onClick={() => setShow(!show)}>
      <div
        className={`flex justify-between items-center h-[34px] w-full rounded-[10px] px-[15px] cursor-pointer ${value ? "dark:text-white text-darkGray" : "dark:text-white text-normalGray"} ${show ? "bg-white dark:bg-gray-40" : "bg-gray-20 dark:bg-gray-40"}`}
      >
        {pointName ? pointName : "Выберите точку самовывоза"}
        <ArrowIcon
          className={`w-[8px] h-[13px] ${show ? "-rotate-90" : "rotate-90"}`}
        />
      </div>
      <div
        className={`absolute top-[38px] rounded-[10px] left-0 w-full max-h-[300px] overflow-y-scroll bg-white dark:bg-gray-40 px-[10px] py-1 z-[10] pretty-scroll ${(!show || !pickupPoints.length) && "hidden"}`}
      >
        {pickupPoints.map((p: any, index: number) => (
          <div
            onClick={() => {
              onChange(p.id);
              setShow(false);
            }}
            className={`p-[10px] cursor-pointer ${value === p.id && "bg-orderbtn rounded-[10px] text-main"} ${index !== 0 && value !== p.id && pickupPoints[index - 1]?.id !== value ? "border-[#E1E1E1] dark:border-normalGray" : "border-white dark:border-gray-40"} border-t-[1px]`}
            key={p.id}
          >
            <div className="text-darkGray dark:text-white leading-[18px]">
              {formatAddress(p, ["city"])}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
