import React, { useState, useEffect } from 'react'
import Price from '../Price';
import Check from './Check'
import Form from './Form';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import { useCartContext } from '../../../../contexts/CartContext';
import { useAppContext } from '../../../../contexts/App';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { NotificationBlock, sendEvent, useVisitorContext } from '../../../../contexts/VisitorContext';
import { AdditionalFields, useAdditionalFieldsFunnelsContext } from '../../../../contexts/AdditionalFieldsFunnelsContext';
import useForm from '../../../../hooks/useForm';
import { apiClient } from '../../../../libs/api/apiClient';
import { localStorageWrap } from '../../../../libs/helpers/localStorageWrap';
import { useGuardedCallback } from '../../../../hooks/useGuardedCallback';
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import ArrowIcon from '../../../icons/ArrowIcon';
import { Preloader } from '../../../common/preloader';
import { useMetricsContext } from '../../../../contexts/MetricsContext';
import WarningBlock from '../WarningBlock';

export default function OrderStep({ back, setCartErrors }: any) {
  const { cart, updateCart, updateCartDebounce, updateDataCart, loading } = useCartContext();
  const { city, branch, address, company, openTimeInfo } = useAppContext()
  const { updateMe, user } = useAuthContext();
  const { metricsEvent } = useMetricsContext();
  const { actionsController, visitorId } = useVisitorContext();
  const navigate = useNavigate()

  const [orderLoading, setOrderLoading] = useState(false);

  useEffect(() => {
    metricsEvent("order-page");
  }, []);

  useEffect(() => {
    if (visitorId) {
      sendEvent("section-visited", { target: "Оформление заказа" });
    }
  }, [visitorId]);

  const {
    data,
    changedFields,
    changedFieldsRefresh,
    updateField,
    updateData,
    errors,
    updateErrors,
    submit
  } = useForm({
    preorder: !openTimeInfo?.isOpen,
    deliveryType: address?.type === "delivery" ? "courier" : "pickup",
    paymentType: branch?.order?.paymentTypes?.at(0)?.value,
    personsCount: 1,
    entrance: cart.entrance,
    floor: cart.floor,
    flat: cart.flat
  });

  const AdditionalFieldsContext = useAdditionalFieldsFunnelsContext();

  useEffect(() => {
    if (Object.values(changedFields).length) {
      updateCartDebounce(changedFields);
      changedFieldsRefresh();
    }
  }, [changedFields]);

  const [waitCartUpdate, setWaitCartUpdate] = useState(false);

  const confirmCb = useGuardedCallback(async () => {
    setOrderLoading(true);
    await handleSubmit();
    setOrderLoading(false);
  });

  useEffect(() => {
    if (waitCartUpdate && !loading) {
      confirmCb();
      setWaitCartUpdate(false);
      setOrderLoading(true);
    }
  }, [loading]);

  const handleSubmit = async () => {
    if (loading) {
      setWaitCartUpdate(true);
      return;
    }

    if (orderLoading) return;

    return submit(async (form: FormData) => {
      const body: any = {
        ...Object.fromEntries(form.entries()),
        cartId: cart.cartId
      };

      if (body?.comment === "") {
        delete body.comment;
      }

      if (data.deliveryType === "courier") {
        body.deliveryAmount =
          cart.options.freeDeliveryFromTotal &&
          !cart.options.isFreeDeliveryDisabled &&
          cart.total - (cart.options.deliveryAmount || 0) >=
            (cart.options.freeDeliveryFromTotal || 0)
            ? 0
            : cart.options.deliveryAmount || 0;
      }

      if (body?.personsCount === "") {
        delete body.personsCount;
      }

      try {
        const res = await apiClient.order.create(branch.id, { body });
        const { errors, status, data, message, cart } = res;

        if (
          status === 500 ||
          (status !== 409 && status !== 422 && status !== 201)
        ) {
          throw JSON.stringify(res);
        }

        actionsController({ order: data?.actions || [] });

        if (status === 422) {
          // Проверяем пришла валидация формы или корзины
          if (errors) {
            // Форма
            updateErrors(errors);
            AdditionalFieldsContext.updateErrors(errors);
            animateScroll.scrollToTop();
          } else {
            // Корзина
            setCartErrors(res?.products?.filter((p: any) => p?.errors?.length));
          }
        } else if (status === 409) {
          if (cart) {
            updateDataCart(cart);
          }
          back(message);
        } else {
          AdditionalFieldsContext.resetForm();
          metricsEvent("order");

          await updateMe();

          const cartData: any = {
            deliveryType: address?.type === "delivery" ? "courier" : "pickup",
            preorder: !openTimeInfo?.isOpen,
            paymentType: branch?.order?.paymentTypes?.at(0)?.value,
            personsCount: 1
          };

          if (user?.phone || localStorageWrap.getItem("clientPhone")) {
            cartData.clientPhone =
              user?.phone || localStorageWrap.getItem("clientPhone");
          }
          
          navigate(`/${city.slug}/order/${data.hash}`)
          
          if (data?.paymentLink) {
            setTimeout(() => {
              window.open(data.paymentLink, '_blank');
            }, 1000)
          }

          await updateCart(cartData);
        }
      } catch (e) {
        console.error(`Create order error - ${e}`);

        toast.error(`${e}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: company.template.theme
        });
      }
    });
  };

  // const handleSubmitGuarded = useGuardedCallback(handleSubmit)

  const confirmEnabled =
    cart.count &&
    ((((cart?.total || 0) >= (cart.options.deliveryFromTotal || 0) ||
      !company.isCustomerAddressMustBeInDeliveryZone) &&
      data.deliveryType === "courier") ||
      data.deliveryType === "pickup") &&
    // allow courier delivery only for valid address in the current branch
    ((data.deliveryAddress && data.deliveryZoneId != null) ||
      data.deliveryType !== "courier" ||
      !company.isCustomerAddressMustBeInDeliveryZone) &&
    ((data.deliveryType === "courier" && cart.options.isCourierUsed) ||
      (data.deliveryType === "pickup" && cart.options.isPickupUsed)) &&
    !cart.options.isMakingOrderDisabled;

  return (
    <>
      <NotificationBlock place="order-top" classNameWrapper="mt-[20px]" />

      <div className="max-w-[1000px] w-full mx-auto px-[15px] lg:px-[75px]">
        <div className="flex gap-[30px] mt-3 md:mt-[30px] mb-[30px]">
          <div className="grow">
            <div className="text-[28px] -tracking-[.01em] font-bold mb-5">
              Заказ на{" "}
              {data.deliveryType == "courier" ? "доставку" : "самовывоз"}
            </div>
            <AdditionalFields place="top-in-order" className="mb-5" />

            <Form
              data={data}
              updateField={updateField}
              errors={errors}
              updateErrors={updateErrors}
              updateData={updateData}
            />

            <AdditionalFields place="bottom-in-order" className="mt-5" />
          </div>

          <Check
            deliveryAmount={
              cart.options.freeDeliveryFromTotal &&
              !cart.options.isFreeDeliveryDisabled &&
              cart.total - (cart.options.deliveryAmount || 0) >=
                (cart.options.freeDeliveryFromTotal || 0)
                ? 0
                : cart.options.deliveryAmount || 0
            }
            deliveryShow={data.deliveryType === "courier"}
          />
        </div>

        <div className="flex flex-col gap-y-[15px]">
          {data.deliveryType === "courier" && (
            <>
              {
                // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
                !!(
                  cart.options?.deliveryFromTotal &&
                  cart.total - (cart.options.deliveryAmount || 0) <
                    cart.options.deliveryFromTotal
                ) && (
                  <WarningBlock>
                    Минимальная сумма заказа для доставки{" "}
                    {formatCurrency(
                      branch.extended.currency.code,
                      cart.options.deliveryFromTotal
                    )}
                  </WarningBlock>
                )
              }
              {
                // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
                !!(
                  cart.options?.freeDeliveryFromTotal &&
                  cart.total - (cart.options.deliveryAmount || 0) <
                    cart.options.freeDeliveryFromTotal &&
                  ((cart.options.deliveryFromTotal &&
                    cart.total - (cart.options.deliveryAmount || 0) >=
                      cart.options.deliveryFromTotal) ||
                    !cart.options.deliveryFromTotal)
                ) && (
                  <WarningBlock>
                    До бесплатной доставки ещё{" "}
                    {formatCurrency(
                      branch.extended.currency.code,
                      cart.options.freeDeliveryFromTotal -
                        cart.total +
                        (cart.options.deliveryAmount || 0)
                    )}
                  </WarningBlock>
                )
              }
            </>
          )}

          {!!(
            cart.cartPromoCode.promoCode &&
            cart.cartPromoCode.reason &&
            !cart.cartPromoCode.activated
          ) && (
            <WarningBlock>
              Промокод не активирован: {cart.cartPromoCode.reason}
            </WarningBlock>
          )}

          {!!cart.options.disabledMakingOrderComment && (
            <WarningBlock>
              {cart.options.disabledMakingOrderComment}
            </WarningBlock>
          )}
        </div>

        <Price
          allProductsPrice={cart.amount}
          totalPrice={cart.total}
          totalDiscount={cart.discounts.discountWithoutBonus}
          deliveryAmount={
            cart.options.freeDeliveryFromTotal &&
            !cart.options.isFreeDeliveryDisabled &&
            cart.total - (cart.options.deliveryAmount || 0) >=
              (cart.options.freeDeliveryFromTotal || 0)
              ? 0
              : cart.options.deliveryAmount || 0
          }
          deliveryShow={data.deliveryType === "courier"}
          bonus={cart.discounts.bonusDiscount}
        />

        {branch.extended.orderPageText && (
          <div className="mt-[50px] -tracking-[.02em] text-gray-50 dark:text-gray-10 text-sm md:text-base">
            {branch.extended.orderPageText}
          </div>
        )}

        <div className="text-xs font-medium text-gray-40 dark:text-gray-10 mt-[55px]">
          Отправляя заказ, вы даете согласие на{" "}
          <a
            href={`/${city.slug}/personalDataPolicy`}
            target="_blank"
            className="text-main relative underline underline-offset-2"
            rel="noreferrer"
          >
            обработку своих персональных данных{" "}
          </a>
          ,{" "}
          <a
            href={`/${city.slug}/termsOfUse`}
            target="_blank"
            className="text-main ml-1 relative underline underline-offset-2"
            rel="noreferrer"
          >
            соглашаетесь с пользовательским соглашением
          </a>{" "}
          <span>и</span>{" "}
          <a
            href={`/${city.slug}/offer`}
            target="_blank"
            className="text-main relative underline underline-offset-2"
            rel="noreferrer"
          >
            пользовательским соглашением (офертой)
          </a>
        </div>

        <div className="flex flex-col-reverse md:flex-row gap-x-[25px] gap-y-[15px] md:items-center justify-between mt-[50px] mb-[50px]">
          <div
            onClick={() => back()}
            className="flex items-center justify-center w-full md:max-w-[489px] h-10 md:h-[45px] bg-gray-20 dark:bg-[#393939] text-[#333] dark:text-[#fff] gap-3 text-lg font-bold rounded-full cursor-pointer"
          >
            <ArrowIcon
              className="rotate-180 h-[13px] w-[8px]"
              colorClassName="fill-[#333] dark:fill-[#fff]"
            />
            <span>Назад в корзину</span>
          </div>

          <div
            onClick={() => confirmCb()}
            className={`${!confirmEnabled && "opacity-50 pointer-events-none"} flex items-center justify-center  w-full md:max-w-[489px] h-10 md:h-[45px] bg-main text-white text-lg font-bold hover:opacity-80 rounded-full cursor-pointer`}
          >
            {!orderLoading ? (
              <span>Оформить заказ</span>
            ) : (
              <Preloader color="white" countOfDot={3} size="10px" />
            )}
          </div>
        </div>
      </div>

      <NotificationBlock place="order-bottom" classNameWrapper="mb-[20px]" />
    </>
  );
}
