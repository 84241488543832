import React, { useMemo } from "react";
import { useAppContext } from "../../../contexts/App";
import { resizeImage } from "../../../libs/helpers/resizeImage";
import productImgStub from '../../../images/productImgStub.jpg'
import { OptionProductType } from "../../../types/cart";
import { formatCurrency } from "../../../libs/helpers/formatCurrency";

export default function Product({ product, isGift = false }: any) {
  const { branch } = useAppContext()

  const options = useMemo(
    () =>
      product?.options?.map((option: any) => ({
        title: option.option.title,
        count: option.quantity
      })) || [],
    [product.options]
  );

  return (
    <div className="md:border-none md:p-[15px] md:bg-white dark:md:bg-gray-50 md:rounded-[29px] md:shadow-lg">
      <div className="flex gap-5 md:gap-[30px]">
        {product.good.image ? (
          <div className="rounded-[17px] overflow-hidden h-[94px] min-w-[94px]">
            <picture className="h-[94px]">
              <source
                className="h-[94px]"
                type="image/webp"
                srcSet={`${resizeImage(product.good.image, "125", "webp")} 1x, ${resizeImage(product.good.image, "300", "webp")} 2x, ${resizeImage(product.good.image, "400", "webp")}3x`}
              />
              <source
                className="h-[94px]"
                type="image/jpeg"
                srcSet={`${resizeImage(product.good.image, "125", "jpg")} 1x, ${resizeImage(product.good.image, "300", "jpg")} 2x, ${resizeImage(product.good.image, "400", "jpg")} 3x`}
              />
              <img
                className="h-[94px]"
                src={resizeImage(product.good.image, "125", "jpg")}
                srcSet={`${resizeImage(product.good.image, "125", "jpg")} 1x, ${resizeImage(product.good.image, "300", "jpg")} 2x, ${resizeImage(product.good.image, "400", "jpg")} 3x`}
                alt=""
              />
            </picture>
          </div>
        ) : (
          <img
            className="w-[94px] h-[94px] rounded-[17px]"
            src={productImgStub}
            alt=""
          />
        )}

        <div className="flex flex-col grow relative">
          <div className="flex md:items-center justify-between">
            <div className="flex items-center flex-wrap gap-x-4 md:text-[24px] font-bold -tracking-[.01em] pr-[10px] md:pr-0">
              <span>{product.good?.title}</span>

              {product.good?.skuGroupsValues?.map((sky: any) => (
                <span className="text-main" key={sky.valueId}>
                  {sky.title}
                </span>
              ))}
            </div>
          </div>

          {!!options.length && (
            <div
              className="text-[18px] text-[#848484] font-medium mb-1"
              dangerouslySetInnerHTML={{
                __html: `+ ${options.map((option: OptionProductType) => `${option.title}${option.count > 1 ? "&nbsp;x&nbsp;" + option.count : ""}`).join(", ")}`
              }}
            />
          )}

          {!!(
            product.good?.weight ||
            product.good?.size ||
            product.good?.volume
          ) && (
            <div className="flex gap-2 -tracking-[.01em] font-bold text-[#848484] text-[18px]">
              {!!product.good?.weight && <div>{product.good?.weight} г.</div>}
              {!!product.good?.size && (
                <div>
                  {product.good?.size} {product.good?.measureUnit}
                </div>
              )}
              {!!product.good?.volume && <div>{product.good?.volume} мл.</div>}
            </div>
          )}

          <div className="absolute bottom-0 right-0 flex flex-col items-end gap-1 hidden md:block">
            {isGift ? (
              <>
                {product?.quantity && product.quantity > 1 && (
                  <div className="text-[20px] font-medium text-gray-40 dark:text-gray-30">
                    x {product.quantity}
                  </div>
                )}
              </>
            ) : (
              <>
                {product?.quantity && product.quantity > 1 && (
                  <div className="text-[20px] font-medium text-gray-40 dark:text-gray-30">
                    {product.quantity} x{" "}
                    {formatCurrency(
                      branch.extended.currency.code,
                      Math.round((product.total * 100) / product.quantity) / 100
                    )}
                  </div>
                )}
              </>
            )}

            <div className="text-[20px] font-bold text-right">
              {isGift
                ? "Подарок"
                : product.total
                  ? formatCurrency(branch.extended.currency.code, product.total)
                  : "Бесплатно"}
            </div>
          </div>

          <div className="md:hidden flex grow items-end">
            <div className="text-[20px] font-bold">
              {product.quantity} x{" "}
              {isGift
                ? "Подарок"
                : product.total
                  ? formatCurrency(
                      branch.extended.currency.code,
                      Math.round((product.total * 100) / product.quantity) / 100
                    )
                  : "Бесплатно"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
