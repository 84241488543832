import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  GeolocationControl,
  Map,
  Placemark,
  useYMaps,
  ZoomControl
} from "@pbe/react-yandex-maps";
import ymaps from "yandex-maps";

import ScreenSearchDelivery from "./ScreenSearchDelivery";
import ScreenSearchPickup from "./ScreenSearchPickup";
import ModalWrap from "./ModalWrap";
import CitySelector from "./CitySelector";
import AddressSearch from "./AddressSearch";
import PointInfo from "./PointInfo";
import ScreenSelectSavedAddress from "./ScreenSelectSavedAddress";
import PickupPointSearch from "./PickupPointSearch";
import { useAddressContext } from "../../../../../contexts/AddressContext";
import { useAuthContext } from "../../../../../contexts/AuthContext";
import { useAppContext } from "../../../../../contexts/App";
import { useCartContext } from "../../../../../contexts/CartContext";
import { apiClient } from "../../../../../libs/api/apiClient";
import { formatAddress } from "../../../../../libs/helpers/formatAddress";
import { timeoutUntilSuccess } from "../../../../../libs/helpers/timeoutUntilSuccess";
import ArrowIcon from "../../../../icons/ArrowIcon";
import CloseIcon from "../../../../icons/CloseIcon";
import { initRoundButtonsLayoutModule } from "../../../../../libs/ymaps/roundButtonsModule";
import geolocationIcon from "../../../../../images/geolocation.svg"
import GeomarkIcon from "../../../../icons/GeomarkIcon";
import ClockIcon from "../../../../icons/ClockIcon"
import { ChangeBranchPopup } from "../Modals";

interface IAddressSelectorPopup {
  isActive: boolean;
  setActive: (v: boolean) => void;
  allPointCity?: boolean;
  type?: string;
}

export default function AddressSelectorPopup({
  isActive,
  setActive,
  allPointCity = true,
  type = "pickup"
}: IAddressSelectorPopup) {
  const { city, company, address, branch } = useAppContext()
  const { user } = useAuthContext();

  const {
    trySaveDeliveryPoint,
    trySavePickupPoint,
    allUserAddresses,
    addressError,
    setAddressError,
    successSelectCallback,
    setSuccessSelectCallback,
    changeBranchPopupCallback
  } = useAddressContext();

  const { addressPreloader, setAddressPreloader } = useCartContext();

  const [mode, setMode] = useState<string>(type); // pickup delivery notZone
  const [newCity, setNewCity] = useState<any>(city); // current city
  const [newBranch, setNewBranch] = useState<any>(branch);

  // ---------------useState for pickup
  const [newPickupPoint, setNewPickupPoint] = useState<any>(null); // current pickup points
  const [pickupPoints, setPickupPoints] = useState<any>([]); // all pickup points
  const [animPoint, setAnimPoint] = useState(false);

  // ---------------useState for delivery
  const [deliveryAddress, setDeliveryAddress] = useState<any>({ name: "" }); // query address
  const [deliveryPoint, setDeliveryPoint] = useState<any>(null); // cur address
  const clickOnAddress = useRef<any>(null); // cur address

  const [isLoading, setLoading] = useState(false);
  const [isMouseDragging, setMouseDragging] = useState(false);
  const [mapHeight, setMapHeight] = useState(0);
  const [showPickupList, setShowPickupList] = useState(false);
  const [showScreenSearchDelivery, setShowScreenSearchDelivery] =
    useState(false);
  const [showScreenSearchPickup, setShowScreenSearchPickup] = useState(false);
  const [showSavedAddressScreen, setShowSavedAddressScreen] = useState(false);

  const mapRef = useRef<ymaps.Map | undefined>();
  const geolocationRef = useRef<any>();
  const placemarksRefs = useRef<{
    [k: string]: React.MutableRefObject<ymaps.Placemark | null>;
  }>({}).current;
  const deliveryPointRef = useRef<ymaps.Placemark | undefined>();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setShowSavedAddressScreen(!!user && !!allUserAddresses.length);
    if (!isActive) {
      setTimeout(() => setSuccessSelectCallback(() => () => {}), 2000);
    }
  }, [isActive]);

  useEffect(() => {
    setShowSavedAddressScreen(!!user && !!allUserAddresses.length);
  }, [!!user, !!allUserAddresses.length]);

  // чтобы менялся мод при открытии модалки когда нет адреса из деталки товара
  useEffect(() => {
    // Если нет в городе зон доставки не даём установить тип выбора адреса кроме как самовывоз
    if (
      city.branches.reduce(
        (accumulator: boolean, currentValue: any) =>
          accumulator ||
          (currentValue.hasDeliveryZones && !currentValue.pickupOnly),
        false
      )
    ) {
      setMode(type);
    } else {
      setMode("pickup");
    }
  }, [type]);

  const [cities, setCities] = useState<any>({});
  useEffect(() => {
    apiClient.delivery.getCities(company.id).then(({ data }) => {
      setCities(data.cities);
    });
  }, []);

  useEffect(() => {
    if (city?.slug !== newCity?.slug) {
      setNewCity(city);
      if (mode === "delivery") {
        setDeliveryPoint(null);
        setDeliveryAddress({ name: "" });
      }
    }
  }, [city?.slug]);

  // -------------------select branch for city-------------------
  useEffect(() => {
    if (city?.slug === newCity?.slug) {
      setNewBranch(branch);
    }
  }, [newCity?.slug]);

  // -------------------get all pickup/delivery points---------------------
  useEffect(() => {
    const temp: any = [];
    if (allPointCity) {
      newCity.branches.forEach((branch: any) => {
        branch.pickupPoints?.forEach((pickupPoint: any) => {
          temp.push({
            city: newCity.slug,
            branchId: branch.id,
            ...pickupPoint
          });
        });
      });
    } else {
      newCity.branches
        .filter((b: any) => b.id === branch.id)
        .at(0)
        ?.pickupPoints?.forEach((pickupPoint: any) => {
          temp.push({
            city: newCity.slug,
            branchId: branch.id,
            ...pickupPoint
          });
        });
    }

    setPickupPoints(temp);

    if (temp.length === 0) setMode("delivery");

    if (address?.point?.city === newCity?.slug && address?.type === "pickup") {
      setNewPickupPoint(address.point);
      // setMode('pickup')
    } else {
      setNewPickupPoint(temp.at(0));
    }
  }, [newCity, address?.point]);

  useEffect(() => {
    if (address?.type === "delivery") {
      setDeliveryPoint(address.point);
      setDeliveryAddress({
        name: formatAddress(address.point, ["city"]) || "",
        addressId: address.point?.addressId
      });
      setMode("delivery");
    }
  }, [address?.point]);

  const selectPickupPoint = (p: any) => {
    setNewPickupPoint(p);
    if (p && mode === "pickup") {
      // panToMap(p.lat, p.lon)
    }
  };

  const centerAtCurrentCity = () => {
    const citySlug = newCity?.slug;

    if (cities[citySlug]) {
      mapRef.current?.setCenter(
        [+cities[citySlug].lat, +cities[citySlug].lon],
        12
      );
      mapRef.current?.container.fitToViewport();
    }
  };

  // ----------------------get delivery address pickupPoint depending on query in dadata------------
  const selectDeliveryAddress = (addr: {
    name: string;
    addressId?: string;
  }) => {
    console.log({ addr });
    setDeliveryAddress(addr);
    if (addr.addressId) {
      const existingAddress = allUserAddresses.find(
        (o) => o.id === addr.addressId
      );
      if (existingAddress) {
        setAddressError(null);
        setDeliveryPoint({
          ...existingAddress.address,
          addressId: addr.addressId
        });
        clickOnAddress.current = true;
        panToAddress({
          coords: [existingAddress.address.lat, existingAddress.address.lon],
          addZoom: false
        });
        return;
      }
    }

    if (mode === "delivery") {
      apiClient.suggestions
        .getAddressSuggestions(
          addr.name,
          newCity ? [newCity.guid] : [],
          newCity.lat,
          newCity.lon,
          {},
          company.id
        )
        .then((res: any) => {
          const {
            data
            // isCoordinatesCanBeClarify,
            // deliveryZone,
            // hasDeliveryZone
          } = res;

          // Для уточнение данных о зоне доставки придётся всегда уточнять адрес по координатам
          // if (
          //   isCoordinatesCanBeClarify &&
          //   data[0]?.isCoordinatesNeedToClarify
          // ) {
          clarifyCoords(data[0]);
          // return;
          // }

          // if (data[0]) {
          //   setAddressError(null);
          //   setDeliveryPoint({ ...data[0], deliveryZone, hasDeliveryZone });
          //   clickOnAddress.current = true;
          //   panToAddress({
          //     coords: [data[0].lat, data[0].lon],
          //     addZoom: false
          //   });
          // }
        });
    }
  };

  const selectSavedDeliveryAddress = (addressId: string) => {
    const existingAddress = allUserAddresses.find((o) => o.id === addressId);
    setDeliveryAddress(existingAddress.address);
    setAddressError(null);
    setDeliveryPoint({
      ...existingAddress.address,
      addressId: addressId
    });
    clickOnAddress.current = true;
    panToAddress({
      coords: [existingAddress.address.lat, existingAddress.address.lon],
      addZoom: false
    });

    setAddressPreloader(true);
    setLoading(true);
    trySaveDeliveryPoint({
      ...existingAddress.address,
      addressId: addressId
    }).then((result: any) => {
      if (result === "notHouse") {
        setAddressError({
          type: "message",
          message: "Пожалуйста, укажите номер дома"
        });
      } else if (result === true) {
        setActive(false);
        successSelectCallback();
      }
      setLoading(false);
      setAddressPreloader(false);
    });
  };

  const ymaps = useYMaps(["templateLayoutFactory"]);
  const clarifyCoords = (addr: any) => {
    setLoading(true);
    apiClient.suggestions
      .getCoordsByAddress(formatAddress(addr, [], true), company.id)
      .then(({ data, deliveryZone, hasDeliveryZone }: any) => {
        if (data) {
          setAddressError(null);
          console.log({ ...addr, deliveryZone, hasDeliveryZone });
          setDeliveryPoint({
            ...addr,
            name: addr.title,
            lat: data.lat,
            lon: data.lon,
            deliveryZone,
            hasDeliveryZone
          });
          setDeliveryAddress({
            ...addr,
            name: addr.title,
            lat: data.lat,
            lon: data.lon,
            deliveryZone,
            hasDeliveryZone
          });
          clickOnAddress.current = true;
          panToAddress({ coords: [data.lat, data.lon], addZoom: false });
        } else {
          // setAddressError({ type: 'message', message: 'Не удалось найти адрес' })
        }
        setLoading(false);
      });
  };

  useEffect(() =>
    timeoutUntilSuccess(() => {
      if (mapContainerRef.current && mapContainerRef.current.offsetHeight > 0) {
        setMapHeight(mapContainerRef.current.offsetHeight);
        return true;
      }
      return false;
    })
  );

  useEffect(() => {
    if (mode === "delivery" && deliveryPoint && isActive)
      return timeoutUntilSuccess(() => {
        if (deliveryPointRef.current && mapRef.current) {
          const curZoom = mapRef.current.getZoom();
          mapRef.current.setCenter(
            [deliveryPoint.lat, deliveryPoint.lon],
            curZoom,
            { duration: 500 }
          );
          mapRef.current.container.fitToViewport();
          // deliveryPointRef.current.balloon.open()
          return true;
        }
        return false;
      });
  }, [mode, isActive]);

  useEffect(() => {
    if (mode === "delivery" && deliveryPoint && isActive) {
    } else if (mode === "delivery" && isActive) return centerAtCurrentCity();
  }, [newCity?.slug, isActive]);

  useEffect(() => {
    if (mode === "pickup" && newPickupPoint && isActive)
      return timeoutUntilSuccess(async () => {
        const placemark = placemarksRefs[newPickupPoint?.id]?.current;
        if (placemark && mapRef.current) {
          mapRef.current?.setCenter([newPickupPoint.lat, newPickupPoint.lon]);
          mapRef.current?.container.fitToViewport();
          if (!ymaps) {
            return true;
          }
          const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
            `
                      <div>
                      <svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M30.4879 27.9911C30.4817 27.9867 30.4807 27.9779 30.4856 27.9721C32.99 25.011 34.5 21.1818 34.5 17C34.5 7.61116 26.8888 0 17.5 0C8.11116 0 0.5 7.61116 0.5 17C0.5 21.1818 2.00995 25.011 4.5144 27.9721C4.51931 27.9779 4.51827 27.9866 4.51212 27.9911C4.50571 27.9958 4.50491 28.0051 4.51042 28.0108L4.94844 28.4657C5.14878 28.6849 5.35478 28.8988 5.56621 29.1072L16.0594 40.004C16.8461 40.8209 18.1539 40.8209 18.9406 40.004L29.4339 29.1071C29.6453 28.8988 29.8512 28.6849 30.0514 28.4658L30.4896 28.0108C30.4951 28.0051 30.4943 27.9958 30.4879 27.9911Z" fill="${company.template.mainColor}"/>
                      <circle cx="17.5" cy="17" r="11" fill="white"/>
                      </svg>
                      </div>
                      `
          );
          const MIconContentLayout = ymaps.templateLayoutFactory.createClass(
            `
                      <div>
                      <svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M30.4879 27.9911C30.4817 27.9867 30.4807 27.9779 30.4856 27.9721C32.99 25.011 34.5 21.1818 34.5 17C34.5 7.61116 26.8888 0 17.5 0C8.11116 0 0.5 7.61116 0.5 17C0.5 21.1818 2.00995 25.011 4.5144 27.9721C4.51931 27.9779 4.51827 27.9866 4.51212 27.9911C4.50571 27.9958 4.50491 28.0051 4.51042 28.0108L4.94844 28.4657C5.14878 28.6849 5.35478 28.8988 5.56621 29.1072L16.0594 40.004C16.8461 40.8209 18.1539 40.8209 18.9406 40.004L29.4339 29.1071C29.6453 28.8988 29.8512 28.6849 30.0514 28.4658L30.4896 28.0108C30.4951 28.0051 30.4943 27.9958 30.4879 27.9911Z" fill="${company.template.mainColor}"/>
                      <circle cx="17.5" cy="17" r="11" fill="${company.template.mainColor}"/>
                      </svg>
                      </div>
                      `
          );
          placemark.options.set({
            iconContentLayout: MIconContentLayout
          });
          for (let i in placemarksRefs) {
            if (newPickupPoint?.id == i || !placemarksRefs[i]) {
              continue;
            }
            placemarksRefs[i]?.current?.options.set({
              iconContentLayout: MyIconContentLayout
            });
          }
          return true;
        }
        return false;
      });
  }, [mode, newPickupPoint, isActive]);

  function checkMapCenterChanged(): () => boolean {
    const center = mapRef.current?.getCenter();
    if (!center) return () => !!mapRef.current;
    return () => {
      if (!mapRef.current) return false;
      const newCenter = mapRef.current.getCenter();
      return !(center[0] === newCenter[0] && center[1] === newCenter[1]);
    };
  }

  const updateDeliveryAddressByCoords = async (
    coords: [number, number],
    wasLoading: boolean
  ): Promise<boolean> => {
    // console.log({coords, wasLoading})
    if (!coords) {
      if (wasLoading) setLoading(false);
      return false;
    }
    const checkIsChanged = checkMapCenterChanged();
    return apiClient.suggestions
      .getAddressByCoords(coords[0], coords[1], 200, company.id)
      .then(({ data, deliveryZone, hasDeliveryZone }) => {
        if (checkIsChanged()) return false;
        if (wasLoading) setLoading(false);
        if (!data || data.length === 0) {
          setAddressError({
            type: "message",
            message: "Не удалось найти адрес"
          });
          setDeliveryPoint(null);
          setDeliveryAddress({ name: "" });
          return false;
        }
        if (data && data[0]) {
          setAddressError(null);
          setDeliveryPoint({ ...data[0], lat: coords[0], lon: coords[1] });
          setDeliveryAddress({
            name: formatAddress(data[0], ["city"]),
            street: data[0].streetWithType,
            house: data[0]?.house,
            block: data[0].block,
            deliveryZone,
            hasDeliveryZone
          });
          if (data[0].cityFiasId !== newCity.guid) {
            const newCity = Object.values(cities).find(
              (c: any) => c.guid === data[0].cityFiasId
            );
            if (newCity) {
              setNewCity(newCity);
            }
          }
          return true;
        }
        // setDeliveryPoint((x: any) => ({...x}))
        return false;
      });
  };

  const updateDeliveryAddressAtMapCenter = async (
    delay: number,
    wasLoading: boolean
  ): Promise<boolean> => {
    const checkIsChanged = checkMapCenterChanged();
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const coords = mapRef.current?.getCenter();
        if (coords && !checkIsChanged())
          resolve(
            updateDeliveryAddressByCoords([coords[0], coords[1]], wasLoading)
          );
        else {
          if (wasLoading) setLoading(false);
          resolve(false);
        }
      }, delay);
    });
  };

  useEffect(() => {
    if (!isActive) return;
    if (addressError?.type === "notZone") return;
    if (mode == "delivery") {
      let isMousePressed = false;

      const handlePressIn = () => {
        // console.log("IN")
        if (!mapRef.current) return;
        setMouseDragging(true);
        isMousePressed = true;
        setAnimPoint(true);
      };

      const handlePressOut = () => {
        if (!isMousePressed) return;
        isMousePressed = false;
        setAnimPoint(false);
        setTimeout(() => {
          if (!isMousePressed) setMouseDragging(false);
        }, 500);
      };

      const handleBoundsChange = (e: any) => {
        if (clickOnAddress.current) {
          clickOnAddress.current = false;
          return;
        }
        // console.log("AI")
        const { oldCenter, newCenter } = e.originalEvent;
        if (oldCenter[0] === newCenter[0] && oldCenter[1] === newCenter[1]) {
          return;
        }
        if (!mapRef.current) {
          return;
        }
        setLoading(true);
        updateDeliveryAddressAtMapCenter(500, true).then();
      };

      const handleDbClick = (event: any) => {
        event.preventDefault();
      };

      let handleWheelPromise = Promise.resolve();
      const handleWheel = (e: any) => {
        e.preventDefault();
        const wheel =
          e._sourceEvent.originalEvent.domEvent.originalEvent.deltaY;
        handleWheelPromise.then(() => {
          if (!mapRef.current) return;
          if (wheel < 0) {
            const zoom = mapRef.current.getZoom();
            handleWheelPromise = mapRef.current?.setZoom(zoom + 1, {
              duration: 200
            });
          } else {
            const zoom = mapRef.current.getZoom();
            handleWheelPromise = mapRef.current?.setZoom(zoom - 1, {
              duration: 200
            });
          }
        });
      };

      const stop = timeoutUntilSuccess(() => {
        if (mapRef.current) {
          window.addEventListener("mouseup", handlePressOut);
          mapRef.current.events.add("mousedown", handlePressIn);
          mapRef.current.events.add("mouseleave", handlePressOut);
          mapRef.current.events.add("boundschange", handleBoundsChange);
          mapRef.current.events.add("dblclick", handleDbClick);
          mapRef.current.events.add("wheel", handleWheel);
          return true;
        }
        return false;
      });
      return () => {
        stop();
        window.removeEventListener("mouseup", handlePressOut);
        if (!mapRef.current) return;
        mapRef.current.events.remove("mousedown", handlePressIn);
        mapRef.current.events.remove("mouseleave", handlePressOut);
        mapRef.current.events.remove("boundschange", handleBoundsChange);
        mapRef.current.events.remove("dblclick", handleDbClick);
        mapRef.current.events.remove("wheel", handleWheel);
      };
    }
  }, [mode, newCity.slug, isActive, addressError?.type === "notZone"]);

  function panToGeolocation(event: any) {
    const position = event.get("position");
    mapRef.current?.setCenter(position, 17, { duration: 1000 });
  }

  function panToAddress({
    coords,
    addZoom = false
  }: {
    coords: [number, number];
    addZoom: boolean;
  }) {
    console.log("panto address", coords);
    let curZoom = mapRef.current?.getZoom();
    curZoom = addZoom ? 17 : curZoom;
    // console.log([coords[0], coords[1]])
    mapRef.current?.setCenter([coords[0], coords[1]], curZoom, {
      duration: 1000
    });
  }

  function getPickupTime(openHours: any, list = false) {
    if (!openHours) {
      return "";
    }

    const daysName = [
      ["sunday", "Вс"],
      ["monday", "Пн"],
      ["tuesday", "Вт"],
      ["wednesday", "Ср"],
      ["thursday", "Чт"],
      ["friday", "Пт"],
      ["saturday", "Сб"]
    ];

    const dayNumber = new Date().getDay();

    const d = openHours[daysName[dayNumber][0]];

    const days = daysName
      .slice(dayNumber + 1)
      .concat(daysName.slice(0, dayNumber))
      .map((dn) => ({ ...openHours[dn[0]], title: dn[1] }));

    if (
      days.reduce(
        (accumulator, currentValue) =>
          accumulator && currentValue.hours[0] === "00:00-24:00",
        true
      )
    ) {
      return "Круглосуточно";
    }

    if (d.active) {
      if (list) {
        return d.hours[0];
      }

      const times = d.hours[0].split("-");
      return `С ${times[0]} до ${times[1]}`;
    }

    if (
      days.reduce(
        (accumulator, currentValue) => accumulator && !currentValue.active,
        true
      )
    ) {
      return "Закрыто";
    }

    for (let i = 0; i < 6; i++) {
      if (days[i].active) {
        const prev = i === 0 ? "Завтра" : days[i].title;
        if (list) {
          return `${prev} ${days[i].hours[0]}`;
        }
        const times = days[i].hours[0].split("-");
        return `${prev} С ${times[0]} до ${times[1]}`;
      }
    }
  }

  // ----------------------------------------PICKUP/DELIVERY POPUP------------------------
  return (
    <ModalWrap
      isActive={isActive}
      close={() => setActive(false)}
      showCloseBtn={!showScreenSearchDelivery && !showScreenSearchPickup}
      showSavedAddressScreen={showSavedAddressScreen}
    >
      <div
        className={`flex ${!showSavedAddressScreen ? "md+:h-full h-full" : "md+:h-full md:h-[100vh] rounded-[29px]"} md+:flex-row flex-col-reverse relative`}
      >
        {showSavedAddressScreen ? (
          <ScreenSelectSavedAddress
            toDelivery={() => {
              setMode("delivery");
              setShowSavedAddressScreen(false);
            }}
            toPickup={() => {
              setMode("pickup");
              setShowSavedAddressScreen(false);
            }}
            selectSavedDeliveryAddress={selectSavedDeliveryAddress}
            deliveryPoint={deliveryPoint}
            pickupPoints={pickupPoints}
            isLoading={isLoading}
          />
        ) : (
          <>
            <div className="md+:min-w-[421px] md+:max-w-[421px] h-[40vh] min-h-[279px] md+:h-full md+:py-[30px] py-[15px] md+:px-[40px] px-[15px] flex flex-col md+:rounded-l-[29px] md+:rounded-tr-[0px] rounded-t-[29px] md+:mt-0 mt-[-29px] z-[1] bg-[#F5F5F5] dark:bg-[#121111]">
              <div className="text-[30px] font-bold leading-[34px] -tracking-[0.16px] mb-[10px] md+:block hidden">
                Выберите адрес и способ получения
              </div>
              <div className="text-[20px] leading-[23px] -tracking-[0.16px] md+:block hidden">
                Чтобы видеть актуальное меню и акции
              </div>

              {/* Свитч переключения Доставка/Самовывоз */}
              <div className="w-full rounded-[10px] bg-orderbtn min-h-[34px] md+:my-5">
                {
                  // Если у всех филиалов в этом городе нет зон доставки или включена настройка "Только самовывоз", то даже не показываем этот блок
                  city.branches.reduce(
                    (accumulator: boolean, currentValue: any) =>
                      accumulator &&
                      (!currentValue.hasDeliveryZones ||
                        currentValue.pickupOnly),
                    true
                  ) ? (
                    <div className="h-full text-center text-main flex items-center justify-center">
                      Самовывоз
                    </div>
                  ) : (
                    <>
                      {pickupPoints?.length === 0 ? (
                        <div className="h-full text-center text-main flex items-center justify-center">
                          Доставка
                        </div>
                      ) : (
                        <div className="relative w-full h-full flex items-center">
                          <div
                            style={{
                              transform: `translate(${mode === "delivery" ? "0" : "100"}%)`
                            }}
                            className="absolute w-1/2 h-full bg-main rounded-[10px] transition duration-150"
                          />
                          <div
                            onClick={() => setMode("delivery")}
                            className={`w-1/2 h-full z-[2] flex items-center justify-center text-center font-medium cursor-pointer ${mode === "delivery" ? "text-white" : "text-main"} transition duration-150`}
                          >
                            Доставка
                          </div>
                          <div
                            onClick={() => setMode("pickup")}
                            className={`w-1/2 h-full z-[2] flex items-center justify-center text-center font-medium cursor-pointer ${mode === "pickup" ? "text-white" : "text-main"} transition duration-150`}
                          >
                            Самовывоз
                          </div>
                        </div>
                      )}
                    </>
                  )
                }
              </div>

              <CitySelector
                cities={cities || {}}
                value={newCity.slug}
                onChange={(slug: any) => {
                  const nCity = cities[slug];
                  if (nCity) {
                    setNewCity(nCity);
                  }
                  setNewBranch(null);
                  setAddressError(null);
                  setDeliveryAddress({ name: "" });
                  setDeliveryPoint(null);
                }}
              />

              {/* Поиск */}
              <div className="mt-[15px]">
                {mode === "pickup" && (
                  <>
                    <div className="hidden md+:block">
                      <PickupPointSearch
                        pickupPoints={pickupPoints}
                        value={newPickupPoint?.id}
                        onChange={(id: any) => {
                          selectPickupPoint(
                            pickupPoints.find((p: any) => p.id === id)
                          );
                        }}
                      />
                    </div>

                    <div className="md+:hidden block">
                      <div
                        onClick={() => setShowScreenSearchPickup(true)}
                        className="flex items-center justify-between h-9 bg-gray-20 dark:bg-gray-40 rounded-[10px] px-[15px] cursor-pointer"
                      >
                        <span className="lines-ellipsis">
                          {formatAddress(newPickupPoint, ["city"])}
                        </span>
                        {pickupPoints?.length > 1 && (
                          <div className={"rotate-90"}>
                            <ArrowIcon className="w-[8px] h-[13px]" />
                          </div>
                        )}
                      </div>
                      <ScreenSearchPickup
                        isActive={showScreenSearchPickup}
                        close={() => setShowScreenSearchPickup(false)}
                        newCity={newCity}
                        pickupPoints={pickupPoints.map((p: any) => ({
                          name: formatAddress(p, ["city"]),
                          ...p
                        }))}
                        selectPickupPoint={selectPickupPoint}
                        getPickupTime={getPickupTime}
                      />
                    </div>
                  </>
                )}

                {mode === "delivery" && (
                  <>
                    <div className="hidden md+:block relative">
                      <AddressSearch
                        newCity={newCity}
                        deliveryAddress={deliveryAddress}
                        selectDeliveryAddress={selectDeliveryAddress}
                        clear={() => {
                          setDeliveryAddress({ name: "" });
                          setDeliveryPoint(null);
                        }}
                      />
                    </div>

                    <div className="md+:hidden block relative">
                      <div
                        onClick={() => setShowScreenSearchDelivery(true)}
                        className="flex justify-between items-center h-9 bg-gray-20 dark:bg-gray-40 rounded-[10px] px-[15px] cursor-pointer"
                      >
                        <span className="lines-ellipsis">
                          {deliveryPoint
                            ? formatAddress(deliveryPoint, ["city"])
                            : "Введите улицу и дом"}
                        </span>
                      </div>
                      <div
                        className={`absolute h-full px-[15px] flex top-0 right-0 z-[1000] items-center justify-center cursor-pointer ${!deliveryAddress.name && "hidden"}`}
                        onClick={() => {
                          setDeliveryAddress({ name: "" });
                          setDeliveryPoint(null);
                        }}
                      >
                        <CloseIcon
                          colorClassName="fill-main "
                          className="w-[10px] h-[10px]"
                        />
                      </div>
                      <ScreenSearchDelivery
                        isActive={showScreenSearchDelivery}
                        close={() => setShowScreenSearchDelivery(false)}
                        deliveryAddress={deliveryAddress}
                        newCity={newCity}
                        selectDeliveryAddress={selectDeliveryAddress}
                      />
                    </div>
                  </>
                )}
              </div>

              <PointInfo
                mode={mode}
                newPickupPoint={newPickupPoint}
                deliveryAddress={deliveryAddress}
                getPickupTime={getPickupTime}
              />

              {/* Кнопка подтверждения выбора */}
              <div
                className={`w-full mt-auto flex items-center justify-center h-[45px] rounded-full bg-main text-white md:text-[20px] font-medium cursor-pointer ${(isLoading || isMouseDragging || addressPreloader || ((!deliveryAddress.name || deliveryAddress.hasDeliveryZone === false) && mode === "delivery")) && "opacity-50 pointer-events-none"}`}
                onClick={() => {
                  if (mode === "pickup") {
                    setAddressPreloader(true);
                    setLoading(true);
                    trySavePickupPoint(newCity, newPickupPoint).then(
                      (success) => {
                        if (success) {
                          successSelectCallback();
                          setActive(false);
                        }
                        setAddressPreloader(false);
                        setLoading(false);
                      }
                    );
                  } else {
                    setAddressPreloader(true);
                    setLoading(true);
                    trySaveDeliveryPoint(deliveryPoint).then((result: any) => {
                      if (result === "notHouse") {
                        setAddressError({
                          type: "message",
                          message: "Пожалуйста, укажите номер дома"
                        });
                      } else if (result === true) {
                        setActive(false);
                        successSelectCallback();
                      }
                      setLoading(false);
                      setAddressPreloader(false);
                    });
                  }
                }}
              >
                Подтвердить
              </div>
            </div>

            <div
              className="relative m-0 w-full overflow-hidden h-full md+:rounded-r-[29px] shadow-[7px_0px_12px_-3px_#0000001A_inset]"
              ref={mapContainerRef}
            >
              {/* Курсор выбора адреса доставки */}
              {mode === "delivery" && !showScreenSearchDelivery && (
                <div
                  ref={deliveryPointRef as any}
                  style={{
                    position: "absolute",
                    bottom: mapHeight / 2,
                    left: "50%"
                  }}
                  className="z-[20]"
                >
                  <div
                    className={`relative h-[22px] w-[4px] rounded-full ${deliveryAddress.hasDeliveryZone === false ? "bg-[#848484]" : "bg-main"}`}
                  >
                    <div
                      className={`absolute -left-[15px] h-[34px] duration-200 w-[34px] rounded-full bottom-[20px] ${deliveryAddress.hasDeliveryZone === false ? "bg-[#848484]" : "bg-main"}`}
                    />
                    <div className="absolute -left-[6px] h-[16px] duration-200 w-[16px] bg-[#fff] rounded-full bottom-[29px]" />
                  </div>
                </div>
              )}

              {/* Свитч выбора вывода точек самовывозв На карте/Списком */}
              {mode === "pickup" && (
                <div
                  style={{ position: "absolute", left: "20px", top: "20px" }}
                  className="z-[20] hidden md+:block"
                >
                  <div className="relative w-[360px] h-[34px] flex items-center bg-[#DEDEDE] dark:bg-dark rounded-[10px] shadow-[0px_7px_12px_-3px_#0000001A]">
                    <div
                      style={{
                        transform: `translate(${showPickupList ? "100" : "0"}%)`
                      }}
                      className="absolute w-1/2 h-full bg-white dark:bg-gray-40 rounded-[10px] transition duration-150"
                    />
                    <div
                      onClick={() => setShowPickupList(false)}
                      className={`w-1/2 h-full z-[2] flex items-center justify-center text-center text-[14px] font-medium cursor-pointer ${!showPickupList ? "" : "text-[#848484]"} transition duration-150`}
                    >
                      На карте
                    </div>
                    <div
                      onClick={() => setShowPickupList(true)}
                      className={`w-1/2 h-full z-[2] flex items-center justify-center text-center text-[14px] font-medium cursor-pointer ${showPickupList ? "" : "text-[#848484]"} transition duration-150`}
                    >
                      Списком
                    </div>
                  </div>
                </div>
              )}

              {/* Карта */}
              <div className='address-popup'>
                <Map
                  instanceRef={mapRef}
                  modules={["geolocation", "geocode"]}
                  onLoad={(ymapsApi) => {
                    initRoundButtonsLayoutModule(ymapsApi);
                  }}
                  width="100%"
                  height={`${mapHeight}px`}
                  defaultState={{
                    zoom: 15,
                    center: city ? [+city.lat, +city.lon] : [55.7522, 37.6156],
                    controls: []
                  }}
                  defaultOptions={{
                    yandexMapDisablePoiInteractivity: true,
                    suppressMapOpenBlock: true
                  }}
                >
                  {mode === "delivery" && (
                    <GeolocationControl
                      data={{
                        image: `${geolocationIcon}`
                      }}
                      instanceRef={geolocationRef}
                      options={{
                        float: "right",
                        layout: "round#buttonLayout",
                        // @ts-ignore
                        noPlacemark: true
                      }}
                      onLocationChange={panToGeolocation}
                    />
                  )}
  
                  <ZoomControl
                    options={{
                      //@ts-ignore
                      layout: "round#zoomLayout",
                      size: "small"
                    }}
                  />
                  {mode === "pickup" &&
                    pickupPoints.map((c: any) => {
                      if (!placemarksRefs[c.id]) {
                        placemarksRefs[c.id] = { current: null };
                      }
                      return (
                        <Placemark
                          instanceRef={placemarksRefs[c.id] as any}
                          key={c.id}
                          geometry={[c.lat, c.lon]}
                          onLoad={(api) => {
                            const MyIconContentLayout =
                              api.templateLayoutFactory.createClass(
                                `
                          <div>
                          <svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M30.4879 27.9911C30.4817 27.9867 30.4807 27.9779 30.4856 27.9721C32.99 25.011 34.5 21.1818 34.5 17C34.5 7.61116 26.8888 0 17.5 0C8.11116 0 0.5 7.61116 0.5 17C0.5 21.1818 2.00995 25.011 4.5144 27.9721C4.51931 27.9779 4.51827 27.9866 4.51212 27.9911C4.50571 27.9958 4.50491 28.0051 4.51042 28.0108L4.94844 28.4657C5.14878 28.6849 5.35478 28.8988 5.56621 29.1072L16.0594 40.004C16.8461 40.8209 18.1539 40.8209 18.9406 40.004L29.4339 29.1071C29.6453 28.8988 29.8512 28.6849 30.0514 28.4658L30.4896 28.0108C30.4951 28.0051 30.4943 27.9958 30.4879 27.9911Z" fill="${company.template.mainColor}"/>
                          <circle cx="17.5" cy="17" r="11" fill="white"/>
                          </svg>
                          </div>
                          `
                              );
                            const MyActiveIconContentLayout =
                              api.templateLayoutFactory.createClass(
                                `
                          <div>
                          <svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M30.4879 27.9911C30.4817 27.9867 30.4807 27.9779 30.4856 27.9721C32.99 25.011 34.5 21.1818 34.5 17C34.5 7.61116 26.8888 0 17.5 0C8.11116 0 0.5 7.61116 0.5 17C0.5 21.1818 2.00995 25.011 4.5144 27.9721C4.51931 27.9779 4.51827 27.9866 4.51212 27.9911C4.50571 27.9958 4.50491 28.0051 4.51042 28.0108L4.94844 28.4657C5.14878 28.6849 5.35478 28.8988 5.56621 29.1072L16.0594 40.004C16.8461 40.8209 18.1539 40.8209 18.9406 40.004L29.4339 29.1071C29.6453 28.8988 29.8512 28.6849 30.0514 28.4658L30.4896 28.0108C30.4951 28.0051 30.4943 27.9958 30.4879 27.9911Z" fill="${company.template.mainColor}"/>
                          <circle cx="17.5" cy="17" r="11" fill="${company.template.mainColor}"/>
                          </svg>
                          </div>
                          `
                              );
  
                            api.ready(() => {
                              placemarksRefs[c.id].current?.options.set({
                                iconContentLayout:
                                  newPickupPoint?.id == c.id
                                    ? MyActiveIconContentLayout
                                    : MyIconContentLayout
                              });
                            });
                          }}
                          options={{
                            hideIconOnBalloonOpen: false,
                            iconLayout: "default#imageWithContent",
                            // iconImageHref: company.template.logoThumbnail,
                            iconImageSize: [40, 40],
                            iconImageOffset: [-20, -40]
                          }}
                          properties={
                            {
                              // iconContent: `<img class="rounded-full border-main" src="${company.template.logoThumbnail}"/>`,
                              // balloonContent : `<div class="border-[1px] border-gray-20 w-full py-1.5 px-3 rounded-full">
                              //                   <p class="font-bold text-gray-50">${formatAddress(c, ['city'])}</p>
                              //                </div>`,
                            }
                          }
                          onClick={() => {
                            setNewPickupPoint(c);
                          }}
                        />
                      );
                    })}
                </Map>
              </div>
              {/* Список точек самовывоза */}
              {showPickupList && mode === "pickup" && (
                <div className="absolute h-[612px] top-0 left-0 w-full pl-5 pt-[84px] pr-10 pb-5 overflow-y-auto no-scroll-bar bg-[#F5F5F5] dark:bg-gray-50">
                  {pickupPoints.map((p: any, index: number) => (
                    <div
                      key={p.id}
                      className="cursor-pointer"
                      onClick={() => selectPickupPoint(p)}
                    >
                      <div
                        className={`${index !== 0 && newPickupPoint?.id !== p.id && pickupPoints[index - 1].id !== newPickupPoint?.id ? "border-[#E1E1E1]" : "border-[#fff] dark:border-gray-50"} border-t-[1px]`}
                      />
                      <div
                        className={`px-[20px] py-[15px] flex flex-col gap-y-1 rounded-[10px] ${newPickupPoint?.id === p.id && "bg-orderbtn"}`}
                      >
                        <div className="font-bold">{p.title}</div>
                        <div className="flex items-center gap-x-[14px] text-[14px]">
                          <GeomarkIcon
                            width="22"
                            height="22"
                            colorClassName={
                              newPickupPoint?.id === p.id
                                ? "fill-main"
                                : "fill-gray-40"
                            }
                          />
                          {p.title}
                        </div>
                        <div className="flex items-center gap-x-[14px] text-[14px]">
                          <ClockIcon
                            color={
                              newPickupPoint?.id === p.id
                                ? company.template.mainColor
                                : "#848484"
                            }
                          />
                          {getPickupTime(p.openHours, true)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <ChangeBranchPopup />
    </ModalWrap>
  );
}
