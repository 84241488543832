"use client";

import { useEffect } from "react";
import { useAppContext } from "../../../contexts/App";

const apiKey = process.env.REACT_APP_YMAPS_API_KEY;

function YandexMapRating() {
  const {
    branch,
    yandexRating,
    setYandexRating,
  } = useAppContext()

  useEffect(() => {
    const init = () => {
      window.ymaps
        // @ts-ignore 
        .findOrganization(`${branch?.yandexId || 0}`)
        .then((orgGeoObject: any) => {
          const rating = orgGeoObject.properties.get("rating");
          setYandexRating(rating.score);
        });
    };

    const loadYandexMaps = () => {
      if (yandexRating) return;
      const script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${apiKey}`;
      script.type = "text/javascript";
      script.onload = () => {
        if (window.ymaps) {
          window.ymaps.ready(init);
        }
      };
      document.head.appendChild(script);
    };

    loadYandexMaps();
  }, [branch, yandexRating, setYandexRating]);

  return null;
}

export default YandexMapRating;