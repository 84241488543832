import React, { useRef, useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import { useAppContext } from "../../../../contexts/App";
import { useProductDetailCardContext } from "../../../../contexts/ProductDetailCardContext";
import { apiClient } from "../../../../libs/api/apiClient";
import { formatCurrency } from "../../../../libs/helpers/formatCurrency";
import { resizeImages } from "../../../../libs/helpers/resizeImage";
import CrossedOldPriceIcon from "../../../icons/CrossedOldPriceIcon";
import productImgStub from "../../../../images/productImgStub.jpg";

export default function Popular() {
  const { branch } = useAppContext()
  const [popularProducts, setPopularProducts] = useState([]);
  const [isSwiping, setIsSwiping] = useState(false);
  const { openDetail } = useProductDetailCardContext();
  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false);
  const [isShowRightArrow, setIsShowRightArrow] = useState(false);

  function getPopularProducts() {
    apiClient.catalog.getPopular(branch.id).then(({ data }) => {
      setPopularProducts(data);
      return data;
    });
  }

  useEffect(() => {
    if (branch.id) {
      getPopularProducts();
    }
  }, [branch.id]);

  const products = useMemo(() => {
    if (popularProducts) {
      return popularProducts.map((p: any) => {
        p.priceString = formatCurrency(branch.extended.currency.code, p.price);
        p.oldPriceString = formatCurrency(
          branch.extended.currency.code,
          p.oldPrice
        );
        p.imageResize = resizeImages(p.image, [125, 300, 400], ["webp", "jpg"]);
        return p;
      });
    }

    return [];
  }, [popularProducts]);

  const settingsSlider = {
    slidesToShow: 1,
    slidesToScroll: 8,
    variableWidth: true,
    infinite: true,
    arrows: false,
    beforeChange: () => setIsSwiping(true),
    afterChange: () => setTimeout(() => setIsSwiping(false), 100),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToScroll: 7
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 1120,
        settings: {
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 510,
        settings: {
          slidesToScroll: 2
        }
      }
    ]
  };

  const slider = useRef<any>(null);

  useEffect(() => {
    if (products.length > 4) {
      setIsShowRightArrow(true);
      setIsShowLeftArrow(true);
    } else {
      setIsShowRightArrow(false);
      setIsShowLeftArrow(false);
    }
  }, [products.length]);

  if (!popularProducts?.length) {
    return <></>;
  }

  return (
    <div className="md:mt-[25px]">
      <div className="text-dark dark:text-light text-2xl md:text-4xl font-bold">
        Популярное
      </div>

      <div className="relative mt-1">
        <button
          className={`${isShowLeftArrow ? "lg:flex" : "lg:hidden"} absolute hidden top-[calc(50%-20px)] left-[-20px] z-[5] h-10 w-10  rounded-full items-center justify-center bg-[#fff] dark:bg-gray-50 stroke-gray-60 dark:stroke-[#848484] hover:stroke-main dark:hover:stroke-main transition-[0.2]`}
          onClick={() => slider.current?.slickPrev()}
        >
          <div className="w-[12px] h-[18px] mr-1">
            <svg
              width="12"
              height="18"
              viewBox="0 0 12 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2L3 9L10 16"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </button>

        <Slider
          {...settingsSlider}
          ref={slider}
          className="popular-slider md:-mx-[8px] -mx-[5px]"
        >
          {products?.map((product: any) => (
            <div
              key={product?.id}
              className="product flex items-center justify-center cursor-pointer md:mx-[8px] mx-[5px] slide"
              onClick={() => !isSwiping && openDetail(product?.id)}
            >
              <div className="mb-5 md:mb-10 mt-5 md:mt-[30px] flex min-h-[233px] w-[147px] cursor-pointer flex-col items-stretch rounded-[29px] bg-white p-[12px] shadow-lg dark:bg-gray-50">
                {product.image ? (
                  <div className="flex h-[123px] w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-2xl">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={`${product.imageResize.webp[125]} 1x, ${product.imageResize.webp[300]} 2x, ${product.imageResize.webp[400]} 3x`}
                      />
                      <source
                        type="image/jpeg"
                        srcSet={`${product.imageResize.jpg[125]} 1x, ${product.imageResize.jpg[300]} 2x, ${product.imageResize.jpg[400]} 3x`}
                      />
                      <img
                        className="h-full w-full rounded-[17px] object-cover"
                        src={product.imageResize.jpg[125]}
                        alt=""
                      />
                    </picture>
                  </div>
                ) : (
                  <div className="flex h-[123px] w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-[17px]">
                    <img
                      className="h-full w-full rounded-[17px] object-cover"
                      src={productImgStub}
                      width={125}
                      height={125}
                      alt=""
                    />
                  </div>
                )}

                <div className="mt-[10px] flex flex-col justify-between flex-grow">
                  <div className="whitespace-normal text-center text-[16px] font-normal leading-[127.02%] -tracking-[.01em] text-dark dark:text-white sm:text-base sm:font-bold lines-ellipsis lines-ellipsis--2">
                    {product.title}
                  </div>

                  <div className="flex flex-row items-baseline justify-center gap-[10px]">
                    {!!product.oldPrice && !product?.optionsExists && (
                      <div
                        className={`relative ${
                          String(product.oldPrice).length >= 4
                            ? "xs:w-[31%]"
                            : "xs:max-w-[21%]"
                        } ml-[2px] mr-2 w-[30%] text-sm leading-[17px] text-gray-50 dark:text-gray-30`}
                      >
                        <div className="flex items-center opacity-60">
                          {product.oldPriceString}
                        </div>
                        <div className="absolute left-0 top-0 h-full w-full">
                          <CrossedOldPriceIcon
                            colorClassName="stroke-main"
                            width={window.innerWidth > 425 ? "100%" : "100%"}
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex items-center gap-1 text-xl font-bold text-main">
                      {product?.optionsExists && "От"} {product.priceString}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <button
          className={`${isShowRightArrow ? "lg:flex" : "lg:hidden"} absolute hidden top-[calc(50%-20px)] right-[-20px] z-[5] h-10 w-10 rounded-full items-center justify-center bg-[#fff] dark:bg-gray-50 stroke-gray-60 dark:stroke-[#848484] hover:stroke-main dark:hover:stroke-main transition-[0.2]`}
          onClick={() => slider.current?.slickNext()}
        >
          <div className="rotate-180 w-[12px] h-[18px] ml-1">
            <svg
              width="12"
              height="18"
              viewBox="0 0 12 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2L3 9L10 16"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}
