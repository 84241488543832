import React, { useState, useEffect, useMemo } from 'react'
import Promo from './Promo'
import Bonus from './Bonus'
import GiftСhoice from './GiftСhoice';
import Products from './Products';
import Price from '../Price';
import RecommendedProducts from './RecommendedProducts';
import { useCartContext } from '../../../../contexts/CartContext';
import { NotificationBlock, sendEvent, useVisitorContext } from '../../../../contexts/VisitorContext';
import { localStorageWrap } from '../../../../libs/helpers/localStorageWrap';
import Popup from '../../../common/popup';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import { AdditionalFields } from '../../../../contexts/AdditionalFieldsFunnelsContext';
import { Preloader } from '../../../common/preloader';
import { useMetricsContext } from '../../../../contexts/MetricsContext';
import PopupActionSheet from '../../../common/popup/PopupActionSheet';
import { BaseButton } from './../../../common/button/BaseButton';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../../icons/ArrowIcon';
import WarningBlock from '../WarningBlock';
import { useAppContext } from '../../../../contexts/App';


export default function CartStep({
  nextOrderPage,
  loaded,
  bonusAmount,
  backToCartMessage
}: any) {
  const {address, branch, openTimeInfo, city} = useAppContext()

  const { cart, synchronize, isInitCart, loading, clearCart } = useCartContext();
  const { visitorId } = useVisitorContext();
  const { metricsEvent } = useMetricsContext();
  const [isActivePopupclearCart, setActivePopupclearCart] = useState(false);

  // -----------------Updating
  // useUpdatePage(
  //   () => {
  //     updateCurrentBranch() //если в будущем придумают как из филиала обновлять определенные поля - заюзать
  //     updateCart({})
  //   }
  // )

  useEffect(() => {
    metricsEvent("cart-page");
  }, []);

  useEffect(() => {
    if (visitorId) {
      sendEvent("section-visited", { target: "Корзина" });
    }
  }, [visitorId]);

  const [showDeliveryInfoPopup, setShowDeliveryInfoPopup] = useState(false);
  const [showedDeliveryInfoPopup, setShowedDeliveryInfoPopup] = useState(false);

  useEffect(() => {
    // Показываем попап с информацией о доставке если не хватает до минимальной суммы заказа на доставку или до порога бесплатной доставки
    if (
      address?.type === "delivery" &&
      !showedDeliveryInfoPopup &&
      cart.deliveryZoneId &&
      ((cart.options.deliveryFromTotal &&
        cart.total - (cart.options.deliveryAmount || 0) <
          cart.options.deliveryFromTotal) ||
        (!!(
          cart.options.freeDeliveryFromTotal &&
          cart.total - (cart.options.deliveryAmount || 0) <
            cart.options.freeDeliveryFromTotal &&
          ((cart.options.deliveryFromTotal &&
            cart.total - (cart.options.deliveryAmount || 0) >
              cart.options.deliveryFromTotal) ||
            !cart.options.deliveryFromTotal)
        ) &&
          !cart.options.isFreeDeliveryDisabled))
    ) {
      setShowDeliveryInfoPopup(true);
      setShowedDeliveryInfoPopup(true);
    } else {
      setShowDeliveryInfoPopup(false);
    }
  }, [address, cart, cart.deliveryZoneId]);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    // На случай если юзверь перейдёт в корзину через url надо это отловить и синхронизировать корзину
    if (
      !JSON.parse(localStorageWrap.getItem("isSynchronizedCart") || "false") &&
      isInitCart
    ) {
      synchronize().then(() => setTimeout(() => setLoad(false), 500));
    } else {
      setLoad(false);
    }
  }, [isInitCart]);

  const isCanOrder = () => {
    if (address?.type === "pickup") {
      console.log(cart);
      return (
        cart?.products?.length &&
        (openTimeInfo?.isOpen || branch.extended.allowOrderAfterHours)
      );
    }

    return (
      cart?.products?.length &&
      (cart?.total || 0) - (cart.options.deliveryAmount || 0) >=
        (cart.options.deliveryFromTotal || 0) &&
      (openTimeInfo?.isOpen || branch.extended.allowOrderAfterHours)
    );
  };

  return (
    <>
      <NotificationBlock place="cart-top" classNameWrapper="mt-[20px]" />

      <div className="w-full max-w-[1000px] mx-auto px-[15px] lg:px-[75px] overflow-x-hidden">
        <Popup
          isActive={showDeliveryInfoPopup}
          close={() => setShowDeliveryInfoPopup(false)}
          closeIcon
        >
          <div className="text-lg font-bold">Доставка</div>
          {!!cart.deliveryZoneId && (
            <div>
              {
                // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
                !!(
                  cart.options.deliveryFromTotal &&
                  cart.total - (cart.options.deliveryAmount || 0) <
                    cart.options.deliveryFromTotal
                ) && (
                  <div className="mt-3 flex items-center gap-1 -tracking-[.01em] text-yellow">
                    Минимальная сумма заказа для доставки{" "}
                    {formatCurrency(
                      branch.extended.currency.code,
                      cart.options.deliveryFromTotal
                    )}
                  </div>
                )
              }
              {
                // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
                !!(
                  cart.options.freeDeliveryFromTotal &&
                  cart.total - (cart.options.deliveryAmount || 0) <
                    cart.options.freeDeliveryFromTotal &&
                  ((cart.options.deliveryFromTotal &&
                    cart.total - (cart.options.deliveryAmount || 0) >
                      cart.options.deliveryFromTotal) ||
                    !cart.options.deliveryFromTotal)
                ) &&
                  !cart.options.isFreeDeliveryDisabled && (
                    <div className="mt-3 flex items-center gap-1 -tracking-[.01em] text-main">
                      До бесплатной доставки ещё{" "}
                      {formatCurrency(
                        branch.extended.currency.code,
                        cart.options.freeDeliveryFromTotal -
                          cart.total +
                          (cart.options.deliveryAmount || 0)
                      )}
                    </div>
                  )
              }
            </div>
          )}
        </Popup>

        {backToCartMessage && (
          <div className="mt-6 gap-[6px] font-bold -tracking-[.01em] text-yellow">
            <span className="md:flex md:items-center inline-block justify-center">
              {backToCartMessage}
            </span>
          </div>
        )}

        <div className="flex flex-col md:flex-row gap-5 md:gap-[30px] my-[30px]">
          {branch.order.fields.promoCode?.isUsed && (
            <Promo className="w-full md:w-1/2 md:mb-6" />
          )}

          {cart.options?.isBonusProgramShown && (
            <Bonus className="w-full md:w-1/2" />
          )}
        </div>

        <AdditionalFields place="top-in-cart" />

        {load ? (
          <Preloader
            countOfDot={3}
            color="main"
            size="8px"
            className="py-[100px] min-w-[122px] gap-2"
          />
        ) : (
          <>
            <GiftСhoice />

            <Products setActivePopupclearCart={setActivePopupclearCart} />

            <RecommendedProducts />
          </>
        )}

        <div className={`${!cart.options.recommendable.length && "md:mt-10"}`}>
          <AdditionalFields place="bottom-in-cart" />
        </div>

        {branch.extended.cartText && (
          <div className="my-3 -tracking-[.02em] text-gray-50 dark:text-gray-10 text-sm md:text-base">
            {branch.extended.cartText}
          </div>
        )}

        <div
          className={`flex flex-col gap-y-[15px] ${
            ((cart.options.disabledMakingOrderComment ||
            (cart.options.deliveryFromTotal &&
              cart.total - (cart.options.deliveryAmount || 0) <
                cart.options.deliveryFromTotal) ||

            (cart.options.freeDeliveryFromTotal &&
              cart.total - (cart.options.deliveryAmount || 0) <
                cart.options.freeDeliveryFromTotal &&
              ((cart.options.deliveryFromTotal &&
                cart.total - (cart.options.deliveryAmount || 0) >=
                  cart.options.deliveryFromTotal) ||
                !cart.options.deliveryFromTotal))) &&
              "mb-[30px]")
          }`}
        >
          {address?.type === "delivery" && cart.deliveryZoneId && (
            <>
              {
                // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
                !!(
                  cart.options.deliveryFromTotal &&
                  cart.total - (cart.options.deliveryAmount || 0) <
                    cart.options.deliveryFromTotal
                ) && (
                  <WarningBlock>
                    Минимальная сумма заказа для доставки{" "}
                    {formatCurrency(
                      branch.extended.currency.code,
                      cart.options.deliveryFromTotal
                    )}
                  </WarningBlock>
                )
              }
              {
                // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
                !!(
                  cart.options.freeDeliveryFromTotal &&
                  cart.total - (cart.options.deliveryAmount || 0) <
                    cart.options.freeDeliveryFromTotal &&
                  ((cart.options.deliveryFromTotal &&
                    cart.total - (cart.options.deliveryAmount || 0) >=
                      cart.options.deliveryFromTotal) ||
                    !cart.options.deliveryFromTotal)
                ) &&
                  !cart.options.isFreeDeliveryDisabled && (
                    <WarningBlock>
                      До бесплатной доставки ещё{" "}
                      {formatCurrency(
                        branch.extended.currency.code,
                        cart.options.freeDeliveryFromTotal -
                          cart.total +
                          (cart.options.deliveryAmount || 0)
                      )}
                    </WarningBlock>
                  )
              }
            </>
          )}

          {cart.options.disabledMakingOrderComment && (
            <WarningBlock>
              {cart.options.disabledMakingOrderComment}
            </WarningBlock>
          )}
        </div>

        <Price
          allProductsPrice={cart.amount}
          totalPrice={cart.total}
          totalDiscount={cart.discounts?.discountWithoutBonus || 0}
          deliveryAmount={
            cart.options.freeDeliveryFromTotal &&
            !cart.options.isFreeDeliveryDisabled &&
            cart.total - (cart.options.deliveryAmount || 0) >=
              (cart.options.freeDeliveryFromTotal || 0)
              ? 0
              : cart.options.deliveryAmount || 0
          }
          deliveryShow={address?.type === "delivery"}
          bonus={cart.discounts?.bonusDiscount}
          className={"!mt-0"}
        />

        <div className="flex flex-col-reverse md:flex-row gap-x-[25px] gap-y-[15px] items-center justify-between mt-[50px] mb-[30px]">
          <Link to={`/${city.slug}`} className="w-full">
            <div
              onClick={() => {}}
              className="flex items-center justify-center w-full md:max-w-[489px] h-10 md:h-[45px] bg-gray-20 dark:bg-[#393939] text-[#333] dark:text-[#fff] gap-3 text-lg font-bold rounded-full cursor-pointer"
            >
              <ArrowIcon
                className="rotate-180 h-[13px] w-[8px]"
                colorClassName="fill-[#333] dark:fill-[#fff]"
              />
              <span>Обратно в меню</span>
            </div>
          </Link>

          <div
            onClick={nextOrderPage}
            className={`${!isCanOrder() && "opacity-50 pointer-events-none"} flex items-center justify-center w-full md:max-w-[489px] h-10 md:h-[45px] bg-orderbtn text-main hover:bg-main hover:text-white text-lg font-bold rounded-full cursor-pointer`}
          >
            {loaded && !loading ? (
              <span>Перейти к оформлению</span>
            ) : (
              <Preloader countOfDot={3} size="10px" />
            )}
          </div>
        </div>
      </div>

      <NotificationBlock place="cart-bottom" classNameWrapper="mb-[30px]" />

      <PopupActionSheet
        isActive={isActivePopupclearCart}
        close={() => setActivePopupclearCart(false)}
        closeIcon
      >
        <p className="text-xl font-medium mb-5 hidden md:block">
          Хотите очистить корзину?
        </p>
        <p className="text-xl font-medium md:hidden">Очистить корзину?</p>
        <div className="flex justify-center xs:justify-end gap-4 md:gap-2 flex-col md:flex-row my-5 md:my-0">
          <BaseButton
            className="w-full bg-orderbtn text-main"
            onClick={() => setActivePopupclearCart(false)}
          >
            Отменить
          </BaseButton>
          <BaseButton
            className="bg-main w-full"
            onClick={() =>
              clearCart().then(() => setActivePopupclearCart(false))
            }
          >
            Подтвердить
          </BaseButton>
        </div>
      </PopupActionSheet>
    </>
  );
}
