import { useEffect, useState } from "react";
import { useDetectorBrowser } from "../../../../../hooks/useDetectorBrowser";
import { useWindowResize } from "../../../../../hooks/useWindowResize";
import CloseIcon from "../../../../icons/CloseIcon";

let globalActivePopupCount = 0;

export default function ModalWrap({
  isActive,
  close,
  children,
  showCloseBtn = true,
  showSavedAddressScreen
}: {
  isActive: boolean;
  close: () => void;
  children: React.ReactNode;
  showCloseBtn?: boolean;
  showSavedAddressScreen: boolean;
}) {
  const [appHeight, setAppHeight] = useState(0);

  useEffect(() => {
    if (isActive) {
      globalActivePopupCount++;
      document.body.classList.add("overflow-y-hidden");
      return () => {
        if (--globalActivePopupCount === 0) {
          document.body.classList.remove("overflow-y-hidden");
        }
      };
    }
  }, [isActive]);

  useEffect(() => {
    function setDynamicHeight() {
      setAppHeight(window.innerHeight);
    }

    if (typeof window !== "undefined") {
      setDynamicHeight();

      window.addEventListener("resize", setDynamicHeight);
      window.addEventListener("orientationchange", setDynamicHeight);

      return () => {
        window.removeEventListener("resize", setDynamicHeight);
        window.removeEventListener("orientationchange", setDynamicHeight);
      };
    }
  }, []);

  const isSafari = useDetectorBrowser().includes("safari");

  const [closeAnimate, setCloseAnimate] = useState(false);

  // re-render on window resize
  const isMobileScreen = useWindowResize({ cvt: (w) => w < 860 });

  function handelClose() {
    if (isMobileScreen) {
      setCloseAnimate(true);
      setTimeout(() => {
        close();
        setCloseAnimate(false);
      }, 300);
    } else {
      close();
    }
  }

  return (
    <div
      className={
        `bg-fixed fixed z-[500] overflow-hidden w-full max-w-screen bottom-0 left-0 ` +
        (!isActive && "hidden")
      }
      style={{ height: `${appHeight}px` }}
    >
      <div className="relative w-full min-h-full flex justify-center items-end md:items-center md:px-4">
        <div
          onClick={handelClose}
          className={`${closeAnimate ? "animate-opacityOut" : "animate-opacityIn"} absolute w-full h-full min-h-full top-0 left-0 bg-[#bdbdbd9e] dark:bg-[#0c0c0cc7]`}
        />

        <div
          style={{ maxWidth: showSavedAddressScreen ? "710px" : "1124px" }}
          className={`hidden md:block relative w-full ${isSafari ? "xs:top-0 top-[-40px] " : ""} rounded-[29px] bg-[#F5F5F5] dark:bg-gray-50 shadow-[4px_4px_20px_rgba(0,0,0,0.07)] p-0 h-[612px] `}
        >
          {showCloseBtn && !isMobileScreen && (
            <div
              onClick={handelClose}
              className="absolute cursor-pointer z-[200]  top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main"
            >
              <CloseIcon
                colorClassName="fill-white"
                className="w-[10px] h-[10px]"
              />
            </div>
          )}

          {!isMobileScreen && children}
        </div>

        <div
          className={`${closeAnimate ? "animate-closeActionSheet" : "animate-openActionSheet"} md+:hidden block absolute bottom-0 left-0 w-full ${!showSavedAddressScreen && "h-full"}`}
        >
          {showCloseBtn && !showSavedAddressScreen && (
            <div
              onClick={handelClose}
              className={`absolute cursor-pointer z-[200] md+:top-[0px] md+:right-[0px] top-[15px] right-[15px] flex justify-center items-center rounded-full md+:w-[20px] w-[35px] md+:h-[20px] h-[35px] bg-main`}
            >
              <CloseIcon
                colorClassName="fill-white"
                className="md+:w-[10px] w-[14px] md+:h-[10px] h-[14px]"
              />
            </div>
          )}

          {showCloseBtn && showSavedAddressScreen && (
            <div
              onClick={handelClose}
              className="absolute cursor-pointer z-[200]  top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main"
            >
              <CloseIcon
                colorClassName="fill-white"
                className="w-[10px] h-[10px]"
              />
            </div>
          )}

          <div
            className={`w-full ${!showSavedAddressScreen && "h-full bg-[#F5F5F5] dark:bg-gray-50"} relative shadow-[4px_4px_20px_rgba(0,0,0,0.07)] p-0 `}
          >
            {isMobileScreen && children}
          </div>
        </div>
      </div>
    </div>
  );
}
