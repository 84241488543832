import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAppContext } from "../../../../contexts/App";
import { apiClient } from "../../../../libs/api/apiClient";
import { formatDate } from "../../../../libs/helpers/formatDate";
import PopupActionSheet from "../../../common/popup/PopupActionSheet";
import { BaseButton } from "../../../common/button/BaseButton";
import StatusBar, { Status } from "../../OrderPage/StatusBar";
import { Preloader } from "../../../common/preloader";
import Products from "../../OrderPage/Products";
import Price from "../../OrderPage/Price";
import { formatAddress } from "../../../../libs/helpers/formatAddress";

export default function OrderPopup({
  orderId,
  close,
  repeatOrderWithConfirm
}: any) {
  const { company, branch } = useAppContext()
  const [order, setOrder] = useState<IOrderDetails | null>(null);
  const [resDateAndTime, setResDateAndTime] = useState<any>(null);
  const [requestError, setRequestError] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      await apiClient.profileOrders
        .getOrder(orderId)
        .then(({ data, status }: any) => {
          if (status === 200) {
            setOrder(data);
            setResDateAndTime(formatDate(new Date(data.completedAt)));
            setRequestError(false);
          } else {
            setRequestError(true);
          }
        });
    };

    if (orderId) {
      fetchOrders();

      const timer = setInterval(() => {
        fetchOrders();
      }, 30000);

      return () => clearInterval(timer);
    }
  }, [orderId]);

  return (
    <PopupActionSheet
      closeIcon
      isActive={!!orderId}
      close={close}
      classNamePopup="!max-w-[890px] !p-0"
      background="bg-[#F5F5F5] dark:bg-dark"
      isMap
    >
      <div className="overflow-y-auto no-scroll-bar max-h-[80vh] py-5 px-5">
        {requestError ? (
          <div className="flex items-center justify-center w-full flex-col">
            <div className="text-[24px] text-center">
              Не удалось получить данные о заказе №{orderId}
            </div>
            <div className="text-[16px] mb-3 text-center">Попробуйте позже</div>
            <BaseButton
              className="bg-main text-white"
              onClick={() => window.location.reload()}
            >
              Обновить страницу
            </BaseButton>
          </div>
        ) : (
          <>
            {order ? (
              <div className="-tracking-[.01em]">
                <div className="text-[24px] md:text-[30px] font-bold ">
                  Заказ №{order.id}
                </div>
                <div className="text-[20px] -trackig-[.01em] text-gray-30 ">
                  {moment(order.createdAt).format("DD.MM.YYYY HH:mm")}
                </div>

                <StatusBar
                  statuses={order.statusTraces}
                  acceptedStatus={{
                    status: "accepted",
                    createdAt: order.createdAt,
                    title: company.statuses.accepted.title || "Принят"
                  }}
                  className="mt-8"
                />

                <div className="flex justify-between ">
                  <div>
                    <div className="md:text-[20px] font-bold -trackig-[.01em] md:block hidden">
                      {order?.address ? "Доставка" : "Самовывоз"}{" "}
                      {moment(order.createdAt).format("DD.MM.YYYY HH:mm")}
                    </div>

                    <div className="text-[20px] text-gray-50 dark:text-gray-30 -trackig-[.01em]">
                      {formatAddress(
                        order?.address
                          ? order?.address
                          : order?.pickupPoint?.address,
                        []
                      )}
                    </div>

                    <div className="text-[20px] -trackig-[.01em] text-gray-30">
                      {order?.address && order?.entrance && (
                        <span className="mr-[3px]">{`под ${order.entrance},`}</span>
                      )}
                      {order?.address && order?.floor && (
                        <span className="mr-[3px]">{`эт ${order.floor},`}</span>
                      )}
                      {order?.address && order?.flat && (
                        <span className="mr-[3px]">{`кв ${order.flat}`}</span>
                      )}
                    </div>
                  </div>

                  {order.paymentType === "online" &&
                    !order?.payment?.isPayed &&
                    order?.payment?.paymentLink && (
                      <a href={order?.payment?.paymentLink}>
                        <BaseButton className="bg-main text-white font-bold">
                          {" "}
                          Оплатить заказ
                        </BaseButton>
                      </a>
                    )}
                </div>

                {!!branch?.messageTemplates
                  ?.descriptionOnPageAfterOrderPlaced && (
                  <div className="mt-[10px] md:text-lg ">
                    {branch.messageTemplates.descriptionOnPageAfterOrderPlaced}
                  </div>
                )}

                <div className="">
                  <Products className="my-[25px] md:my-[40px]" order={order} />
                  <Price
                    amount={order.amount}
                    discount={order.discount}
                    total={order.total}
                    bonus={order.bonusDiscount}
                    deliveryAmount={order.deliveryAmount}
                  />
                  <div className="mt-[30px] md:mt-[40px] flex flex-col md:flex-row gap-[15px] md:gap-[30px]">
                    <a
                      href={`tel:${branch?.contacts?.at(0)?.phone}`}
                      className="w-full h-[45px] flex items-center justify-center rounded-full cursor-pointer text-[16px] md:text-[20px] font-medium bg-gray-20 dark:bg-gray-40"
                    >
                      Связаться с нами
                    </a>
                    {(order.status === "completed" ||
                      order.status === "cancelled") && (
                      <div
                        onClick={() => repeatOrderWithConfirm(order.id)}
                        className="w-full h-[45px] flex items-center justify-center bg-orderbtn rounded-full cursor-pointer text-[16px] md:text-[20px] font-medium text-main hover:bg-main hover:text-white"
                      >
                        Повторить заказ
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center w-full ">
                <Preloader countOfDot={4} size="10px" />
              </div>
            )}
          </>
        )}
      </div>
    </PopupActionSheet>
  );
}

interface IOptions {
  option: {
    title: string;
    weight: string;
    volume: number;
  };
  quantity: number;
  amount: number;
}

interface IGoods {
  good: {
    title: string;
    weight: number | null;
    volume: number | null;
    measureUnit: string | null;
    size: string | null;
    description: string;
    image: string;
    skuGroupsValues: [
      {
        valueId: string;
        skuGroupId: number;
        title: string;
      }
    ];
  };
  options: IOptions[];
  quantity: number;
  amount: number;
  discount: number | null;
  total: number;
  isGift: boolean;
  bonusDiscount: number;
}

interface IOrderDetails {
  id: number;
  createdAt: string;
  status: string;
  total: number | null;
  review: {
    status: string;
    estimation: string;
  } | null;
  address: { title: string } | null;
  pickupPoint: {
    address: {
      title: string;
    };
  } | null;
  flat: string | null;
  entrance: string | null;
  floor: number | null;
  amount: number;
  discount: number | null;
  completedAt: string;
  statusTraces: Status[];
  clientName: string;
  clientPhone: string | null;
  goods: IGoods[];
  paymentType: string;
  bonusDiscount: number;
  deliveryAmount: number;
  payment: {
    isPayed: boolean;
    paymentLink: string | null;
  };
}
