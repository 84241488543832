import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/App";
import { useAuthContext } from "../../contexts/AuthContext";
import { sendEvent, useVisitorContext } from "../../contexts/VisitorContext";
import { useProductDetailCardContext } from "../../contexts/ProductDetailCardContext";
import { usePagination } from "../../hooks/usePagination";
import { apiClient } from "../../libs/api/apiClient";
import { useUpdatePage } from "../../hooks/useUpdatePage";
import Popup from "../../components/common/popup";
import OneStartIcon from "../../components/icons/oneStarIcon";
import { BaseButton } from "../../components/common/button/BaseButton";
import { formatDate } from "../../libs/helpers/formatDate";
import { Preloader } from "../../components/common/preloader";
import StarRating from "../../components/common/starRating";
import { ShowFullText } from "../../components/common/showFullText";
import AuthPopup from "../../components/common/popup/auth/Auth";
import { useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";
import pluralize from "../../libs/helpers/pluralize";

interface IGood{
  id: number | string,
  title: string
}

interface IOrder {
  goods: {good: IGood}[] 
}

interface IImage {
  position: number,
  id: string,
  link: string
}

interface IReview {
  answer: null | string
  createdAt: string
  customer: {name: string}
  estimation: number
  id: string
  images: IImage[] | []
  order: IOrder
  text: string
}


export default function Reviews() {
  const [openPopupImage, setOpenPopupImage] = useState(false)
  const [currentImagesForPopup, setCurrentImagesForPopup] = useState<any>([])
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);
  const [currentImage, setCurrentImage] = useState<any>({})
  const navigate = useNavigate();

  const {company, city, branch} = useAppContext()
  const [isAuthDialogOpen, setAuthDialogOpen] = useState(false)
  const { user, updateUser } = useAuthContext()
  const { openDetail } = useProductDetailCardContext()
  const { visitorId } = useVisitorContext()

  useEffect(() => {
    animateScroll.scrollToTop()
  }, [])

  useEffect(() => {
    if (visitorId) {
      sendEvent('section-visited', {target: 'Отзывы'})
    }
  }, [visitorId])

  //-------------pagination
  const {allData, nextPageAction, updateDataAction, isFetching, isInitialLoading, additionalData} = usePagination(
    async (limit, page) => {
      return await apiClient.reviews.getReviews(branch.id ,limit, page)
    }, {
      identity: item => item.id,
      startingPage: 1,
      deps: [branch.id]
    })


  //--------------updating
  useUpdatePage(
    () => {
      //console.log("-------------------Update Reviews-------------------")
      updateDataAction(5)
    }, {interval: 1000 * 60 * 30, deps: [branch.id]})


  //-------------detect bottom
  useEffect(() => {
    const scrollHandler = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

      if (scrollHeight - (scrollTop + clientHeight) < 250) {
        nextPageAction()
      }
    };
    document.addEventListener("scroll", scrollHandler);
    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const rateOfAllReviews = additionalData.additional?.avgEstimation || '0'
  const meta = additionalData.meta || {}

  //------------------------image next/prev
  const handleNext = () => {
    if (activeImageIndex !== null) {
      if (activeImageIndex + 1 >= currentImagesForPopup.length) {
        setActiveImageIndex(0);
        return;
      }
      setActiveImageIndex((prev) => {
        if (prev !== null) {
          return prev + 1;
        }
        return prev;
      });
    }
  };

  const handlePrev = () => {
    if (activeImageIndex !== null) {
      if (activeImageIndex - 1 < 0) {
        setActiveImageIndex(currentImagesForPopup.length - 1);
        return;
      }
      setActiveImageIndex((prev) => {
        if (prev !== null) {
          return prev - 1;
        }
        return 0;
      });
    }
  };

  useEffect(() => {
    if (activeImageIndex !== null) {
      const newImage = currentImagesForPopup[activeImageIndex];
      setCurrentImage(newImage)
    }
  }, [activeImageIndex]);


  return (
    <>
      <Popup
        closeIcon
        isActive={openPopupImage}
        close={() => setOpenPopupImage(false)}
        classNamePopup="w-fit max-h-[75vh]"
        width="fit-content"
        isImagesPopup={true}
        handleNext={handleNext}
        handlePrev={handlePrev}
        isShowArrows={currentImagesForPopup.length > 1 ? true : false}
      >
        <div className="rounded-[29px] overflow-hidden">
          <img src={currentImage.link} alt="" className="h-full max-h-[75vh] object-cover"/>
        </div>
      </Popup>

      <div className="container">

        <div className={`${!isInitialLoading && 'border-b dark:border-gray-40'} `}>
          <div className="md:mt-[20px] mb-[25px] md:mt-0 mt-[15px] flex items-center md+:w-[850px] justify-between">
            <h2 className="md:text-4xl font-bold text-2xl text-dark dark:text-light">Отзывы</h2>

            {!isInitialLoading ? <div className="flex items-end">
              <p className="font-bold xs:text-lg text-sm pb-[3px] text-dark dark:text-light">
                {meta.total
                  ? `${meta.total} ${pluralize(meta.total, ["отзыв", "отзыва", "отзывов"])}`
                  : "Нет отзывов"}
              </p>
              <div className="flex items-center">
                <h2 className="xs:text-4xl text-2xl font-bold ml-[15px] mr-[5px] align-bottom text-dark dark:text-light">{rateOfAllReviews}</h2>
                <OneStartIcon rateOfAllReviews={rateOfAllReviews}/>
              </div>

            </div>: <></>}
          </div>
            {!isInitialLoading &&
              (( user) ?
                <div>
                  <p className="text-gray-50 xs:text-base text-sm dark:text-white">Если вы уже совершили заказ, напишите собственный отзыв к заказу</p>
                  <BaseButton
                    onClick={() => {
                      if (!user?.phone_confirmed) {
                        navigate(`/${city.slug}/account/profile`)
                      }else {
                        navigate(`/${city.slug}/account/history`)
                      }
                    }}
                    className="bg-orderbtn text-main hover:bg-main hover:text-white w-[200px] mb-[25px] mt-[20px]">
                    Оставить отзыв
                  </BaseButton>
                </div>
                :
                <div>
                  <p className="text-gray-50 xs:text-base text-sm">Чтобы оставить свой отзыв, необходимо сначала авторизоваться</p>
                  <BaseButton
                    onClick={() => setAuthDialogOpen(true)}
                    className="bg-orderbtn text-main hover:bg-main hover:text-white w-[200px] mb-[25px] mt-[20px]"
                  >
                    Авторизоваться
                  </BaseButton>
                </div>)

            }
        </div>
        {isInitialLoading &&  <div className={'w-full'}><div className={'w-full py-[20vh]'}><Preloader countOfDot={4} /></div></div>}
        <div className="xs:mt-[40px] mt-[25px] md+:w-[850px] w-full xs:mb-[60px] mb-[50px] flex flex-col gap-y-5">
          {!isInitialLoading && allData && allData.map((review) => {
            const {resDate} = formatDate(new Date(review.createdAt))

            return(
              <div key={review.id} className="bg-white dark:bg-gray-50 shadow-md flex flex-col rounded-[29px] xs:px-[25px] xs:py-[15px] px-[15px] py-[25px]">
            <div className="border-b dark:border-gray-40 font-bold pb-[11px] flex xs:flex-row flex-col xs:items-start xs:justify-between">
                <div>
                  <h3 className="text-xl text-dark dark:text-light">{review.customer?.name}</h3>
                  <p className="text-gray-30 text-sm">{resDate}</p>
                </div>
                <div className="pt-[7px]">
                  <StarRating fillRate={true} rate={review.estimation}/>
                </div>
              </div>
              <div className="xs:pt-[10px] pt-[15px]">
                <p className="text-gray-50 dark:text-white xs:text-base break-words text-sm">{review.text}</p>
              </div>
              {review.images.length?
                <div className="flex flex-wrap xs:gap-x-5 gap-x-[15px] xs:gap-y-0 gap-y-2 overflow-hidden mt-[20px] xs:mb-[20px] mb-[15px]">
                  {review.images.map((image:any, index: number) =>
                    <img onClick={() => {
                      setOpenPopupImage(true)
                      setCurrentImage(image)
                      setCurrentImagesForPopup(review.images)
                      setActiveImageIndex(index)
                    }} key={image.id} src={image.link} alt="" className="object-cover w-[120px] h-[120px] rounded-2xl cursor-pointer"/>
                  )}
                </div>
                :
                <span></span>
              }
              <div className="xs:text-base text-sm mb-[10px] mt-[10px] flex gap-x-[10px] ">
                <div className="text-gray-30 w-full">Содержание заказа:
                  {review.order.goods.map((good: any) => {
                    return (
                      <>
                        {good.good.id?
                          <span onClick={() => openDetail(good.good.id)} key={good.good.id} className="text-main hover:opacity-80 cursor-pointer xs:ml-[20px] ml-[10px]">
                            {good.good.title}
                          </span>
                          :
                          <span className="xs:ml-[20px] ml-[10px] text-gray-40 dark:text-gray-20">{good.good.title}</span>
                        }
                      </>
                    )
                  })}
                </div>
              </div>
              {review.answer &&
                <div className="bg-gray-20 dark:bg-gray-40 w-full mt-[20px] h-fit rounded-[10px] p-[15px]">
                  <ShowFullText
                    text={review.answer}
                    headerTextForClick="Ответ от представителя компании"
                  />
                </div>
              }


            </div>
            )
          })}
          {!isInitialLoading && isFetching && <Preloader countOfDot={4} size="10px"/>}
        </div>

      </div>
    </>
  )
}