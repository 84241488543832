import React, { useEffect, useMemo, useState } from 'react'
import { useCartContext } from '../../../../contexts/CartContext'
import { useAppContext } from '../../../../contexts/App'
import { CartProductType } from '../../../../types/cart'
import Product from '../../../common/products/Product'
import GiftProduct from '../../../common/products/GiftProduct'
import TrashCanIcon from '../../../icons/TrashCanIcon'

export default function Products({ setActivePopupclearCart }: any) {
  const { cart, updateProductServer } = useCartContext();
  const { allProducts } = useAppContext()

  const products = useMemo<any>(() => {
    const tempProducts: any = {};
    cart.products.forEach((product: any) => {
      let productData: any = {};

      if (allProducts[product.productId]) {
        productData = allProducts[product.productId];
      }

      productData = { ...productData, ...product };

      if (
        tempProducts[
          `${product.uid}${product.isGift}${product.isAdded}${!product.total}`
        ]
      ) {
        tempProducts[
          `${product.uid}${product.isGift}${product.isAdded}${!product.total}`
        ].count += product.count;
        tempProducts[
          `${product.uid}${product.isGift}${product.isAdded}${!product.total}`
        ].amount += product.amount;
        tempProducts[
          `${product.uid}${product.isGift}${product.isAdded}${!product.total}`
        ].total += product.total;
      } else {
        tempProducts[
          `${product.uid}${product.isGift}${product.isAdded}${!product.total}`
        ] = productData;
      }
    });

    return Object.values(tempProducts);
  }, [JSON.stringify(cart.products)]);

  return (
    <div
      className={`w-full ${!cart.options.giftsForChoose.length && "mt-[30px]"}`}
    >
      <div className="flex items-center justify-between md:mb-[15px]">
        <div className="font-bold text-3xl">Ваш заказ</div>
        <div
          onClick={() => setActivePopupclearCart(true)}
          className="flex items-center text-main text-[14px] cursor-pointer"
        >
          <TrashCanIcon className="w-[20px] h-[20px]" />
        </div>
      </div>

      {cart.products?.length ? (
        <div className="flex flex-col gap-[15px] md:gap-5 md:mb-[20px] mb-[30px] md:mt-0 mt-[20px]">
          {products
            .filter((p: CartProductType) => !p.isGift)
            .map((productData: any, index: number) => {
              const productCountAllPosition = Object.values(cart.products)
                .filter((p) => p.uid === productData.uid)
                .reduce(
                  (previousValue, currentValue) =>
                    previousValue + currentValue.count,
                  0
                );

              return (
                <Product
                  key={productData.uid + productData.total}
                  productData={productData}
                  addProduct={(setLoad: (value: boolean) => void) => {
                    setLoad(true);
                    updateProductServer(productData.uid, {
                      count: productCountAllPosition + 1
                    }).then(() => setLoad(false));
                  }}
                  removeProduct={(setLoad: (value: boolean) => void) => {
                    setLoad(true);
                    updateProductServer(productData.uid, {
                      count:
                        productData?.minCount &&
                        productData?.minCount == productCountAllPosition
                          ? 0
                          : productCountAllPosition - 1
                    }).then(() => setLoad(false));
                  }}
                  isCart
                />
              );
            })}
          {products
            .filter((p: any) => p.isGift)
            .map((productData: any) => (
              <GiftProduct key={productData.uid} productData={productData} />
            ))}
        </div>
      ) : (
        <div className="flex justify-center">Ваша корзина пуста</div>
      )}
    </div>
  );
}
