import { useMemo } from "react";
import { useAppContext } from "../../../../contexts/App";
import { useCartContext } from "../../../../contexts/CartContext";
import { ProductTag } from "../../../../pages/[city]";
import { resizeImages } from "../../../../libs/helpers/resizeImage";
import { formatCurrency } from "../../../../libs/helpers/formatCurrency";
import { SVGWrap } from "../../../common/util/SVGWrap";
import CrossedOldPriceIcon from "../../../icons/CrossedOldPriceIcon";
import { useAnimationProductsCartContext } from "../../../../contexts/AnimationCartContext";
import Counter from "../../../common/button/Counter";
import productImgStub from "../../../../images/productImgStub.jpg";
import { useProductDetailCardContext } from "../../../../contexts/ProductDetailCardContext";


type ProductCard = {
  productId: number;
  emptyImageClassName?: any;
  isLastProduct?: boolean;
  tags?: Array<ProductTag>;
};

export default function ProductCardVerticalTwoRow({
  productId,
  emptyImageClassName,
  tags = []
}: ProductCard) {
  const { branch, allProducts, address } = useAppContext()

  const { openDetail } = useProductDetailCardContext();

  const {
    setNoAddressWarning,
    setTextInAddressAlert,
    setHeaderTextInAddressAlert
  } = useCartContext();

  const product = useMemo(() => {
    const product = allProducts[productId];

    if (!product) {
      console.log(`Товар ${productId} не найден в allProducts`);
      return null;
    }

    return {
      ...product,
      imageResize: resizeImages(product?.image, [400, 600, 0], ["webp", "jpg"]),
      actuallyTags: tags.filter((tag: any) => product?.tags?.includes(tag.id)),
      priceString: formatCurrency(branch.extended.currency.code, product.price),
      oldPriceString: formatCurrency(
        branch.extended.currency.code,
        product.oldPrice
      )
    };
  }, [productId]);

  if (!product) {
    return <></>;
  }

  return (
    <div
      onClick={() => openDetail(product.id)}
      className={`flex flex-col rounded-[29px] bg-white p-[10px] md:p-[15px] shadow-md dark:bg-gray-50 cursor-pointer`}
    >
      <div className="relative overflow-hidden">
        <div className="absolute z-[11] grid h-full w-full grid-cols-3 grid-rows-3 items-center justify-items-center xs:grid-cols-5 xs:grid-rows-5 xs:pt-1 md:grid-cols-4 lg:grid-cols-5 lg:gap-y-0 lg:pt-0 xl:grid-cols-6">
          {product.tags &&
            product.actuallyTags.map((tag: any, index: number) => (
              <div
                key={index}
                className="h-[25px] w-[25px] rounded-full bg-white xs:h-[32px] xs:w-[32px]"
              >
                <div
                  className="flex h-[25px] w-[25px] items-center justify-center rounded-full xs:h-[32px] xs:w-[32px]"
                  style={{ backgroundColor: tag.background }}
                >
                  <SVGWrap
                    width="13px"
                    height="13px"
                    src={tag.icon}
                    color={tag.color}
                  />
                </div>
              </div>
            ))}
        </div>

        <div className={`relative overflow-hidden rounded-[17px] w-full`}>
          <img
            className={`w-full rounded-[17px] p-[0.5px] ${emptyImageClassName}`}
            src={productImgStub}
            alt=""
          />
          <div className="absolute left-0 top-0 z-[10] h-full w-full">
            {product.image && (
              <picture style={{ height: "auto" }} className="min-w-full">
                <source
                  className="w-full"
                  type="image/webp"
                  srcSet={`${product.imageResize.webp[400]} 1x, ${product.imageResize.webp[600]} 2x, ${product.imageResize.webp[0]} 2.5x`}
                />
                <source
                  className="w-full"
                  type="image/jpeg"
                  srcSet={`${product.imageResize.jpg[400]} 1x, ${product.imageResize.jpg[600]} 2x, ${product.imageResize.jpg[0]} 2.5x`}
                />
                <img
                  loading="lazy"
                  decoding="async"
                  // @ts-ignore
                  fetchPriority="low"
                  style={{ contentVisibility: "auto" }}
                  className="w-full border-dark duration-500"
                  src={product.imageResize.jpg[400]}
                  srcSet={`${product.imageResize.jpg[600]} 2x, ${product.imageResize.jpg[0]} 2.5x`}
                  alt=""
                />
              </picture>
            )}
          </div>
        </div>
      </div>

      <div className={`flex grow flex-col justify-between xs:w-full w-full`}>
        <div className="mt-[10px]">
          <div className="flex items-center gap-x-[6px] font-bold">
            <div className={`flex text-[20px] leading-[22px]text-main`}>
              {!!(product?.skuGroupsValues.length || product?.optionsExists) &&
                "От "}
              {product.priceString}
            </div>
            {!!product.oldPrice && (
              <div className="relative inline-block text-[14px] leading-[17px] text-normalGray dark:text-gray-30">
                <div className="opacity-60">{product.oldPriceString}</div>
                <div className="absolute inset-0 flex items-center">
                  <CrossedOldPriceIcon width="100%" height="100%" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`mt-[10px] -tracking-[.01em] dark:text-white lines-ellipsis lines-ellipsis--2`}>
          {product.title}
        </div>

        {product.weight || product.size || product.volume ? (
          <div
            className={`mt-[4px] flex gap-1 text-sm -tracking-[.01em] text-normalGray`}
          >
            {!!product.weight && <div>{product.weight} г.</div>}
            {!!product.size && (
              <div>
                {product.size} {product.measure_unit}
              </div>
            )}
            {!!product.volume && <div>{product.volume} мл.</div>}
          </div>
        ) : (
          <div className={`h-[16px]`} />
        )}

        <div
          className={`mt-[15px] flex items-end justify-between md+:min-h-[45px]`}
        >
          {product?.skuGroupsValues.length ||
          product?.optionsExists ||
          (!product.canBuyForFree && product.price === 0) ? (
            <div
              onClick={(event) => {
                event.stopPropagation();
                if (address?.point == null) {
                  setTextInAddressAlert(
                    "Чтобы добавить блюдо в корзину нужно указать адрес. Хотим убедиться, что вы в зоне доставки."
                  );
                  setHeaderTextInAddressAlert("Какой у вас адрес?");
                  setNoAddressWarning("toast");
                } else {
                  openDetail(product.id);
                }
              }}
              className={`flex h-[35px] w-full cursor-pointer items-center justify-center rounded-[18px] bg-orderbtn text-base font-bold -tracking-[.01em] text-main duration-500 hover:bg-main hover:text-white`}
            >
              Выбрать
            </div>
          ) : (
            <ActionBlock product={product} />
          )}
        </div>
      </div>
    </div>
  );
}

function ActionBlock({ product, orientationIsVertical = false }: any) {
  const { address } = useAppContext();
  const { addProductToAnimation, removeProductFromAnimation } =
    useAnimationProductsCartContext();
  const { cart, addProduct, getProductCart, updateProduct } = useCartContext();

  const cartProduct = useMemo(
    () => getProductCart(product.id, []),
    [cart.raw, product.id]
  );

  return (
    <>
      {cartProduct?.count ? (
        <Counter
          count={cartProduct.count}
          add={() => {
            if (address) {
              addProductToAnimation(product.id);
            }

            updateProduct(cartProduct.uid, { count: cartProduct.count + 1 });
          }}
          remove={() => {
            removeProductFromAnimation(product.id);

            updateProduct(cartProduct.uid, {
              count:
                product.minCount && product.minCount == cartProduct.count
                  ? 0
                  : cartProduct.count - 1
            });
          }}
          disabledAdd={
            product.stockBalance && cartProduct.count >= product.stockBalance
          }
          className={"w-full"}
        />
      ) : (
        <div
          onClick={(event) => {
            event.stopPropagation();
            console.log(address);
            if (address) {
              addProductToAnimation(product.id);
            }
            addProduct(product.id, [], product.minCount);
          }}
          className={`flex h-[35px] w-full cursor-pointer items-center justify-center rounded-[18px] bg-orderbtn text-base font-bold -tracking-[.01em] text-main duration-500 hover:bg-main hover:text-white`}
        >
          В корзину
        </div>
      )}
    </>
  );
}
