import React from "react";

export default function StarIcon({
  width = 35,
  height = 35,
  className = "transition duration-150 ease-in",
  colorClassName = "fill-gray-30",
  onMouseEnter,
  onMouseLeave
}: {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  colorClassName?: string;
  width?: number;
  height?: number;
}) {
  return (
    <div
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <svg
        width={width}
        height={height}
        className={`${className} transition duration-150 ease-in  ${colorClassName}`}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.5 0L23.0546 9.85479L34.1435 12.0922L26.4875 20.4202L27.7862 31.6578L17.5 26.95L7.21376 31.6578L8.51252 20.4202L0.85651 12.0922L11.9454 9.85479L17.5 0Z" />
      </svg>
    </div>
  );
}
