import React from 'react'
import { useAppContext } from '../../../contexts/App'
import { formatCurrency } from '../../../libs/helpers/formatCurrency'
import { useCartContext } from '../../../contexts/CartContext'

interface Props {
  allProductsPrice: number;
  totalPrice: number;
  totalDiscount: number;
  deliveryAmount?: number | null;
  deliveryShow?: boolean;
  bonus?: number;
  className?: string;
}

export default function Price({
  allProductsPrice,
  totalPrice,
  totalDiscount,
  deliveryAmount = null,
  deliveryShow = true,
  bonus = 0,
  className = ""
}: Props) {
  const { branch } = useAppContext()
  const { cart } = useCartContext();

  return (
    <div className={`mt-[30px] ${className}`}>
      <div className="flex flex-col gap-4 pb-5 border-b-[1px] border-gray-30/40">
        <div className="flex items-center justify-between text-2xl font-bold -tracking-[.01em]">
          <div>Сумма:</div>
          <div className="flex items-center gap-[6px]">
            {formatCurrency(branch.extended.currency.code, allProductsPrice)}
          </div>
        </div>

        {totalDiscount > 0 && (
          <div className="flex items-start justify-between text-xl font-bold -tracking-[.01em] text-gray-50 dark:text-gray-30">
            <div>
              <div>Скидка:</div>
              {cart.discounts?.comments?.map((comment: string) => (
                <div
                  key={comment}
                  className="text-[14px] -tracking-[.01em] text-main font-normal leading-4"
                >
                  {comment}
                </div>
              ))}
            </div>
            <div className="flex items-center gap-[6px]">
              {formatCurrency(branch.extended.currency.code, totalDiscount)}
            </div>
          </div>
        )}

        {!!bonus && (
          <div className="flex items-center justify-between text-xl font-medium -tracking-[.01em] text-gray-50 dark:text-gray-30">
            <div>Списано баллов:</div>
            <div className="flex items-center gap-[6px]">{bonus}</div>
          </div>
        )}

        {deliveryShow &&
          (deliveryAmount || !branch.order.hideDeliveryPrice) && (
            <div className="flex items-center justify-between text-xl font-medium -tracking-[.01em] text-gray-50 dark:text-gray-30">
              <div>Доставка:</div>
              <div className="flex items-center gap-[6px]">
                {deliveryAmount ? (
                  <>
                    +
                    {formatCurrency(
                      branch.extended.currency.code,
                      deliveryAmount
                    )}
                  </>
                ) : (
                  "Бесплатно"
                )}{" "}
              </div>
            </div>
          )}
      </div>

      <div className="flex items-center justify-between text-3xl font-bold -tracking-[.01em] mt-4">
        <div className="text-main">Итого:</div>
        <div className="flex items-center gap-[6px] text-main">
          {formatCurrency(branch.extended.currency.code, totalPrice)}
        </div>
      </div>
    </div>
  );
}
