export default function YandexWhiteIcon({
  width = "18",
  height = "17"
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85266 17C13.5471 17 17.3527 13.1944 17.3527 8.5C17.3527 3.80558 13.5471 0 8.85266 0C4.15824 0 0.352661 3.80558 0.352661 8.5C0.352661 13.1944 4.15824 17 8.85266 17ZM8.22985 5.17C8.50276 5.11857 8.78736 5.09286 9.08366 5.09286C9.26299 5.09286 9.41894 5.09714 9.55149 5.10571C9.69184 5.10571 9.8127 5.11 9.91406 5.11857C10.031 5.12714 10.1324 5.13143 10.2182 5.13143V8.48714H9.04857C8.30783 8.48714 7.75032 8.36714 7.37605 8.12714C7.00958 7.87857 6.82634 7.43714 6.82634 6.80286C6.82634 6.46857 6.88872 6.19 7.01348 5.96714C7.13824 5.74429 7.30588 5.56857 7.5164 5.44C7.72693 5.31143 7.96475 5.22143 8.22985 5.17ZM9.70354 4.01286C9.5164 4.00429 9.34097 4 9.17722 4C8.63141 4 8.14019 4.06 7.70354 4.18C7.26689 4.3 6.89262 4.48 6.58073 4.72C6.27664 4.95143 6.04272 5.24286 5.87898 5.59429C5.71523 5.94571 5.63336 6.35286 5.63336 6.81571C5.63336 7.22714 5.68405 7.58286 5.78541 7.88286C5.89457 8.17429 6.03102 8.41857 6.19477 8.61571C6.35851 8.81286 6.53785 8.96714 6.73278 9.07857C6.92771 9.19 7.11484 9.27571 7.29418 9.33571C7.16943 9.51571 7.02128 9.74714 6.84974 10.03C6.68599 10.3043 6.51056 10.6043 6.32342 10.93C6.14408 11.2557 5.96865 11.5986 5.79711 11.9586C5.62557 12.3186 5.47742 12.6657 5.35266 13H6.54564C6.6782 12.6829 6.82245 12.3571 6.97839 12.0229C7.14213 11.6886 7.30588 11.3671 7.46962 11.0586C7.63336 10.75 7.79711 10.4586 7.96085 10.1843C8.12459 9.91 8.27664 9.67 8.41699 9.46429C8.61972 9.49 8.77956 9.50714 8.89652 9.51571H9.2474H10.2182V13H11.3527V4.21857C11.1967 4.17571 11.0252 4.14143 10.838 4.11571C10.6509 4.09 10.4599 4.06857 10.2649 4.05143C10.0778 4.03429 9.89067 4.02143 9.70354 4.01286Z"
        fill="white"
      />
    </svg>
  );
}
