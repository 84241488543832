import ArrowIcon from "../../../../icons/ArrowIcon";
import GeomarkIcon from "../../../../icons/GeomarkIcon";
import ClockIcon from "../../../../icons/ClockIcon";

export default function ScreenSearchPickup({
  isActive,
  close,
  pickupPoints,
  selectPickupPoint,
  getPickupTime
}: any) {
  return (
    <div className={`relative ${!isActive && "hidden"}`}>
      <div className="fixed w-[100vw] h-[100vh] bg-[#fff] dark:bg-dark z-[1000] top-0 left-0 py-5 px-[15px]">
        <div
          onClick={close}
          className="flex items-center gap-x-[15px] fomt-medium cursor-pointer"
        >
          <ArrowIcon className="w-[13px] h-[13px] rotate-180" />
          Назад
        </div>

        <div
          className="overflow-y-auto no-scroll-bar"
          style={{ height: "calc(100vh - 150px)" }}
        >
          {pickupPoints.map((p: any, index: any) => (
            <div
              key={p.id}
              className="cursor-pointer"
              onClick={() => {
                selectPickupPoint(p);
                close();
              }}
            >
              <div
                className={`${index !== 0 ? "border-[#E1E1E1]" : "border-[#fff] dark:border-dark"} border-t-[1px]`}
              />
              <div className="px-[20px] py-[15px] flex flex-col gap-y-1 rounded-[10px]">
                <div className="font-bold">{p.title}</div>
                <div className="flex items-center gap-x-[14px] text-[14px]">
                  <GeomarkIcon
                    width="22"
                    height="22"
                    colorClassName="fill-gray-40"
                  />
                  {p.title}
                </div>
                <div className="flex items-center gap-x-[14px] text-[14px]">
                  <ClockIcon color="#848484" />
                  {getPickupTime(p.openHours, true)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
