import React from "react";
import Product from "./Product";

export default function Products({ order, className = "" }: any) {
  return (
    <div className={className}>
      <div className="text-[30px] font-bold mb-[30px]">Состав заказа</div>

      <div className="flex flex-col gap-y-5">
        {order.goods
          .filter((p: any) => !p?.isGift)
          .map((product: any, index: number) => (
            <Product key={`${index}_${product.total}`} product={product} />
          ))}
        {order.goods
          .filter((p: any) => p?.isGift)
          .map((product: any, index: number) => (
            <Product
              key={`${index}_${product.total}`}
              product={product}
              isGift
            />
          ))}
      </div>
    </div>
  );
}