import React from "react";

export default function GeomarkIcon({
  className = "",
  colorClassName = "fill-main",
  width = "9",
  height = "13"
}) {
  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 9 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.80445 1.47802C7.20296 0.805505 6.11138 0 4.36634 0C1.49999 0 0 2.32048 0 4.61138C0 7.61173 4.12129 12.2453 4.2995 12.4448C4.3812 12.5409 4.5 12.6 4.62625 12.6H4.63366C4.75991 12.6 4.8713 12.5409 4.9604 12.4522C5.00496 12.4079 5.97774 11.3585 6.96536 9.88784C8.31685 7.87774 9 6.09675 9 4.60397C9 3.46592 8.56187 2.32786 7.80445 1.47802Z"
          className={`${colorClassName}`}
        />
        <path
          d="M2.50879 4.96599C2.50879 3.87228 3.39988 2.98547 4.49888 2.98547C5.59788 2.98547 6.48897 3.87228 6.48897 4.96599C6.48897 6.05971 5.59788 6.94651 4.49888 6.94651C3.39988 6.94651 2.50879 6.05971 2.50879 4.96599Z"
          fill="#EEEEEE"
        />
      </svg>
    </div>
  );
}
