import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../contexts/App';

export default function GisWidget() {
  const { branch } = useAppContext()
  const [decodedHtml, setDecodedHtml] = useState('')
  const [id, setId] = useState('')

  useEffect(() => {
    if (branch?.twoGisCode) {
      let indexIdStart = branch.twoGisCode.indexOf('id')
      indexIdStart = branch.twoGisCode.indexOf('"', indexIdStart) + 1
      let indexIdEnd = branch.twoGisCode.indexOf('"', indexIdStart)

      let id = branch.twoGisCode.substring(indexIdStart, indexIdEnd)

      setId(id)

      let htmlBase64Start = branch.twoGisCode.indexOf(id, indexIdEnd) + id.length + 1
      htmlBase64Start =  branch.twoGisCode.indexOf('"', htmlBase64Start) + 1
      let htmlBase64End = branch.twoGisCode.indexOf('"', htmlBase64Start)

      let htmlBase64 = branch.twoGisCode.substring(htmlBase64Start, htmlBase64End)

      setDecodedHtml(atob(htmlBase64))
    }
  }, [branch?.twoGisCode])

  if (!branch?.twoGisCode || !decodedHtml || !id) {
    return <></>
  }

  return (
    <iframe
      id={id}
      width="150px"
      height="50px"
      srcDoc={decodedHtml} // Здесь передаётся декодированный HTML
      sandbox="allow-scripts allow-same-origin allow-popups"
    ></iframe>
  )
}