import React, { useState, useRef, useEffect } from "react";
import { useCartContext } from '../../../../contexts/CartContext';
import { useAuthContext } from '../../../../contexts/AuthContext';

export default function Bonus({ className='' }: any) {
  
  const [bonusInput, setBonusInput] = useState<any>('')
  const { cart, updateCart } = useCartContext()
  const [isUseBonuse, setIsUseBonuse] = useState(!!cart.discounts?.bonusDiscount)
  const { user } = useAuthContext()

  function handleClick() {
    if (cart.discounts?.bonusDiscount) {
      updateCart({bonusDiscount: 0})
    } else {
      updateCart({bonusDiscount: Math.floor(+bonusInput) > Math.floor(cart.discounts?.maxAppliedBonusDiscount) ? Math.floor(cart.discounts?.maxAppliedBonusDiscount) : Math.floor(+bonusInput)})
    }
  }

  useEffect(() => {
    setBonusInput(cart.discounts?.bonusDiscount || 0)

    if (cart.discounts?.bonusDiscount) {
      setIsUseBonuse(true)
    }
  }, [cart.discounts?.bonusDiscount])

  return (
    <div className={` ${className}`}>
      <div className={`flex items-center gap-4 font-bold text-xl mb-[10px]`}>
        <div className='text-dark dark:text-light'>Бонусные баллы</div>
        {
          (!cart.discounts.isBonusDiscountCanBeUsed)  ?
          <>
            <div className='text-main'><span className='blur-[3px]'>358</span> <span className='text-sm'>баллов</span></div>
            <Tooltip text={'Для использования бонусной программы Вам необходимо авторизоваться'}/>
          </>
          :
          <div className='text-main'>{cart.discounts.availableBonus ? Math.floor(cart.discounts.availableBonus) : Math.floor(user.bonusScore)} <span className='text-sm'>баллов</span></div>
        }
        
        {!!cart.discounts?.maxAppliedBonusDiscount && !!cart.discounts.isBonusDiscountCanBeUsed && 
          <Tooltip isShowTooltip={isUseBonuse? true : false} text={`Максимальное кол-во баллов для списания: ${Math.floor(cart.discounts?.maxAppliedBonusDiscount)}`}/>
        }
      </div>
      
      <div className='flex flex-col gap-5 w-full'>
        <div className='h-[35px] w-full rounded-[10px] bg-orderbtn p-[3px]'>
          <div className='relative h-full w-full flex '>
            <div className={`absolute h-full w-1/2 rounded-[10px] bg-main duration-500 ${isUseBonuse ? 'translate-x-full' : 'translate-x-0'}`} />
            <div onClick={() => {
              setIsUseBonuse(false)
              if (cart.discounts?.bonusDiscount) {
                updateCart({bonusDiscount: 0})
              }
            }} className={`w-1/2 flex items-center justify-center cursor-pointer z-[10] text-sm font-bold duration-500 ${ isUseBonuse ? 'text-main' : 'text-white' }`}>Не использовать</div>
            <div onClick={() => {
              if (cart.discounts?.maxAppliedBonusDiscount && cart.discounts.isBonusDiscountCanBeUsed) {
                setIsUseBonuse(true)
              } 
            }} className={`w-1/2 flex items-center justify-center cursor-pointer z-[10] text-sm font-bold duration-500 ${ !isUseBonuse ? 'text-main' : 'text-white' } ${cart.discounts.isBonusDiscountCanBeUsed ? '' : 'opacity-50'}`}>Использовать</div>
          </div>
        </div>

        <div className={`flex h-[35px] w-full rounded-full bg-gray-20 dark:bg-gray-40 ${isUseBonuse ? '' : 'hidden'}`} >
          <input 
            type="number" 
            placeholder='Количество' 
            className={`min-w-0 h-full grow bg-transparent  text-sm text-main font-medium outline-none rounded-full pl-[26px] pr-3 duration-500 `}
            value={bonusInput}
            onChange={e => setBonusInput(e.target.value)}
            disabled={!!cart.discounts?.bonusDiscount}
          />

          <div onClick={handleClick} className=' flex items-center justify-center bg-orderbtn w-[138px] h-full rounded-full text-sm md:text-base text-main text-sm font-bold cursor-pointer'>{!!cart.discounts?.bonusDiscount ? 'Сбросить' : 'Применить'}</div>
        </div>
      </div>
    </div>
  )
}

function Tooltip({isShowTooltip = false, text}: any) {
  const [show, setShow] = useState(isShowTooltip)
  const wrapper: any = useRef(null);

  useEffect(() => {
    setShow(isShowTooltip)
  }, [isShowTooltip])

  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]); 

  return (
    <>
      <div className={'hidden md:block'}>
        <div className='relative group'>
          <div className='text-sm flex items-center justify-center h-5 w-5 border-2 border-gray-50 dark:border-gray-10 rounded-full text-gray-50 dark:text-gray-10'>i</div>
          <Modal show={show} text={text}/>
        </div>
        
      </div>
      <div className='md:hidden block' ref={wrapper}>
        <div>
          <div onClick={() => setShow(!show)} className='text-sm flex items-center justify-center h-5 w-5 border-2 border-gray-50 dark:border-gray-10 rounded-full'>i</div>
          <Modal show={show} text={text}/>
        </div>
      </div>
    </>
  )
}

function Modal({ show, text }: any) {
  return (
    <div className={`absolute z-[11] xs:top-[38px] right-4 md:right-[-25px] lg:right-[-50px] scale-0 md:group-hover:scale-100 duration-200 p-5 w-[230px] bg-gray-10 dark:bg-gray-40 rounded-[18px] text-gray-50 dark:text-white text-xs font-semibold shadow-lg ${show && 'scale-100'}`}>
      {text}
    </div>
  )
}
