import React from "react";

export default function ClockIcon({
  width = "22",
  height = "22",
  color = "#848484"
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3161_1715)">
        <path
          d="M6.87838 18.1387L5.50488 20.6169"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1216 18.1387L16.4951 20.6169"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 19.2432C15.5523 19.2432 19.2427 15.5528 19.2427 11.0005C19.2427 6.44819 15.5523 2.75781 11 2.75781C6.4477 2.75781 2.75732 6.44819 2.75732 11.0005C2.75732 15.5528 6.4477 19.2432 11 19.2432Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.03333 8.80392C2.40053 8.32962 1.91754 7.68326 1.64202 6.94199C1.36649 6.20071 1.31005 5.39582 1.47944 4.62335C1.64883 3.85087 2.03688 3.14345 2.59731 2.58548C3.15774 2.02751 3.86686 1.64257 4.64007 1.47657C5.41328 1.31058 6.21792 1.37056 6.95798 1.64934C7.69803 1.92812 8.34226 2.41394 8.81377 3.04883"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 2.75854L11 1.38477"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6.88086V11.0022"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 11.002L13.9142 13.9162"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.967 8.80392C19.5998 8.32962 20.0828 7.68326 20.3583 6.94199C20.6338 6.20071 20.6902 5.39582 20.5209 4.62335C20.3515 3.85087 19.9634 3.14345 19.403 2.58548C18.8426 2.02751 18.1334 1.64257 17.3602 1.47657C16.587 1.31058 15.7824 1.37056 15.0423 1.64934C14.3023 1.92812 13.658 2.41394 13.1865 3.04883"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3161_1715">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
