import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useAppContext } from "../../../contexts/App";
import { resizeImage } from "../../../libs/helpers/resizeImage";


export default function MainPageSlider() {
  const { branch } = useAppContext()
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlideMobile, setActiveSlideMobile] = useState(0);
  const [isSwipe, setIsSwipe] = useState(false);

  const settingsSlider = {
    centerPadding: "0px",
    slidesToShow: 1,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToScroll: 1,
    pauseOnHover: false,
    beforeChange: (current: number, next: number) => {
      setIsSwipe(true);
      setActiveSlide(next);
    },
    afterChange: (current: number) => setIsSwipe(false),
    responsive: [
      {
        breakpoint: 520,
        settings: {
          dots: false
        }
      }
    ]
  };

  const settingsSliderMobile = {
    centerPadding: "0px",
    slidesToShow: 1,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToScroll: 1,
    pauseOnHover: false,
    beforeChange: (current: number, next: number) => {
      setIsSwipe(true);
      setActiveSlideMobile(next);
    },
    afterChange: (current: number) => setIsSwipe(false),
    responsive: [
      {
        breakpoint: 520,
        settings: {
          dots: false
        }
      }
    ]
  };

  const slider = useRef<any>(null);
  const sliderMobile = useRef<any>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      slider.current?.slickNext();
      sliderMobile.current?.slickNext();
    }, 7500);

    return () => clearInterval(timer);
  }, []);

  const desktopSlides = branch.desktopSlides.map((url: string, index: number) => (
    <div
      className={`slide w-full overflow-hidden rounded-[29px] outline-none md:h-[255px] ${index !== activeSlide && "side-slide"} ${index < activeSlide && !isSwipe ? "left-slide" : index > activeSlide && !isSwipe ? "right-slide" : ""}`}
      key={`desktop-slide-${index}`}
    >
      <picture className="h-full w-full">
        <source
          media="(min-width: 769px)"
          srcSet={resizeImage(url, 0, "webp")}
          type="image/webp"
        />
        <source
          media="(max-width: 768px)"
          srcSet={resizeImage(url, 768, "webp")}
          type="image/webp"
        />
        <source
          media="(min-width: 766px)"
          srcSet={resizeImage(url, 0, "jpg")}
          type="image/jpeg"
        />
        <source
          media="(max-width: 768px)"
          srcSet={resizeImage(url, 768, "jpg")}
          type="image/jpeg"
        />
        <img
          srcSet={`${resizeImage(url, 768, "jpg")} 768w`}
          sizes="(max-width: 768px) 768px"
          src={resizeImage(url, 0, "jpg")}
          alt="image description"
          className="h-full w-full"
        />
      </picture>
    </div>
  ));

  const mobileSlides = branch.mobileSlides.map((url: string, index: number) => (
    <div
      className={`slide w-full overflow-hidden rounded-[29px] outline-none md:h-[426px] ${index !== activeSlideMobile && "side-slide"} ${index < activeSlideMobile && !isSwipe ? "left-slide" : index > activeSlideMobile && !isSwipe ? "right-slide" : ""}`}
      key={`mobile-slide-${index}`}
    >
      <img className="w-full rounded-[29px] object-cover" src={url} alt="" />
    </div>
  ));

  return (
    <>
      {!!desktopSlides.length && (
        <div className="main-page-slider hidden xs:block md:h-[255px] relative">
          <div
            className="absolute w-[1000px] h-[100%] left-[-1000px] z-[100] cursor-pointer"
            onClick={() => slider.current?.slickPrev()}
          />
          <Slider ref={slider} {...settingsSlider}>
            {desktopSlides}
          </Slider>
          <div
            className="absolute w-[1000px] h-[100%] right-[-1000px] top-0 z-[100] cursor-pointer"
            onClick={() => slider.current?.slickNext()}
          />
        </div>
      )}

      {!!mobileSlides.length && (
        <div
          className={`main-page-slider block xs:hidden ${
            branch.mobileSlides.length > 0 ? "mt-5" : ""
          }`}
        >
          <Slider ref={sliderMobile} {...settingsSliderMobile}>
            {mobileSlides}
          </Slider>
        </div>
      )}
    </>
  );
}
