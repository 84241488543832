import { useAddressContext } from "../../../../../contexts/AddressContext";
import { formatAddress } from "../../../../../libs/helpers/formatAddress";
import GeomarkIcon from "../../../../icons/GeomarkIcon";
import { Preloader } from "../../../preloader";

export default function ScreenSelectSavedAddress({
  toDelivery,
  toPickup,
  selectSavedDeliveryAddress,
  deliveryPoint,
  pickupPoints,
  isLoading
}: any) {
  const { allUserAddresses } = useAddressContext();

  return (
    <div className="relative w-full md:h-full h-[513px] bg-[#F5F5F5] dark:bg-[#121111] md:rounded-[29px] rounded-t-[29px] flex flex-col pt-[30px] md:pb-[30px] pb-[20px] md:px-[40px] px-[15px]">
      {
        isLoading &&
        <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-gray-20 opacity-20">
          <Preloader />
        </div>  
      }

      <div className="font-bold text-[30px] mb-[20px] hidden md:block">
        Мои адреса
      </div>
      {pickupPoints.length > 0 && (
        <div className=" w-full min-h-[34px] flex bg-orderbtn rounded-[10px]">
          <div className="w-1/2 flex items-center justify-center bg-main cursor-pointer rounded-[10px] text-white font-medium">
            Доставка
          </div>
          <div
            onClick={toPickup}
            className="w-1/2 flex items-center justify-center cursor-pointer rounded-[10px] text-main font-medium"
          >
            Самовывоз
          </div>
        </div>
      )}

      <div className="grow overflow-y-scroll no-scroll-bar mt-[20px] md:mb-[20px] mb-[5px]">
        {allUserAddresses.map(({ id, address }: any, index: number) => (
          <div
            key={id + ""}
            onClick={() => {
              selectSavedDeliveryAddress(id);
            }}
            className={`py-[15px] md:px-[15px] px-[10px] ${deliveryPoint?.addressId === id && "bg-orderbtn rounded-[10px]"} ${index !== 0 && deliveryPoint?.addressId !== id && allUserAddresses[index - 1]?.id !== deliveryPoint?.addressId ? "border-[#E1E1E1] dark:border-normalGray" : "border-[#F5F5F5] dark:border-[#121111]"} border-t-[1px] cursor-pointer flex items-center gap-x-[12px]`}
          >
            <GeomarkIcon
              colorClassName={`${deliveryPoint?.addressId === id ? "fill-main" : "fill-normalGray"}`}
              width="14"
              height="20"
            />

            <div>
              <div
                className={`font-bold md:text-[18px] mb-[5px] md:leading-[20px] leading-[18px] ${deliveryPoint?.addressId === id ? "text-main" : "text-darkGray dark:text-white"}`}
              >
                {formatAddress(address, ["city"])}
              </div>
              <div className="text-normalGray md:block hidden leading-[18px] ">
                <span className="lines-ellipsis">
                  {formatAddress(address, ["house", "street", "block"], true)}
                </span>
              </div>
              <div className="text-normalGray text-[14px] leading-[16px] md:hidden">
                {formatAddress(address, ["house", "street", "block"], true)}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        onClick={toDelivery}
        className="w-full min-h-[45px] bg-orderbtn flex items-center md:justify-center cursor-pointer text-main md:rounded-[29px] rounded-[10px] font-medium px-[15px]"
      >
        <div className="md:hidden mr-[10px]">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 0V14M0 7H14" className="stroke-main" strokeWidth="2" />
          </svg>
        </div>
        Добавить новый адрес
      </div>
    </div>
  );
}
