import React, { useEffect, useState } from "react";
import { useMetricsContext } from "../../../contexts/MetricsContext";
import { useAppContext } from "../../../contexts/App";
import { Link, useLocation } from "react-router-dom";
import { NotificationBlock } from "../../../contexts/VisitorContext";
import Smartphone from "../../icons/Smartphone";
import { BaseButton } from "../button/BaseButton";
import YandexIcon from "../../icons/YandexIcon";
import YandexWhiteIcon from "../../icons/YandexWhiteIcon";
import StarRatingIcon from "../../icons/StarRatingIcon";
import logoFooter2 from "../../../images/logoFooter2.svg";
import logoFooter2Light from "../../../images/logoFooter2Light.svg";
import AppStore from "../../icons/AppStore";
import GooglePlay from "../../icons/GooglePlay";
import RuStore from "../../icons/RuStore";
import TelegramRoundIcon from "../../icons/TelegramRoundIcon";
import VkRoundIcon from "../../icons/VkRoundIcon";
import QRCode from "react-qr-code";
import GisWidget from "../GisWidget";


export default function Footer() {
  const { company, branch, city, yandexRating } = useAppContext()
  let location = useLocation();

  const [showInstallApp, setShowInstallApp] = useState(false);

  useEffect(() => {
    if (
      !(location.pathname?.split('/')?.pop() === "/cart") && 
      !(location.pathname.includes("/order/")) &&
      company?.template?.appMobileTitle &&
      (company.iosAppLink || company.androidAppLink || company.rustoreAppLink)
    ) {
      setShowInstallApp(true);
    }
  }, [location.pathname, company]);

  const theme = company?.template?.theme;

  const { metricsEvent } = useMetricsContext();

  return (
    <footer
      className={`overflow-hidden bg-gray-10 px-[15px] dark:bg-gray-50 md:flex-row shadow-[0px_-4px_80px_rgba(0,0,0,0.06)] ${showInstallApp && "pt-[30px] lg:pt-[50px]"}`}
    >
      <NotificationBlock
        place="footer"
        classNameWrapper={`md:mb-[30px] mb-[20px] ${!showInstallApp && "mt-[50px]"}`}
      />
      <div className="container">
        {showInstallApp && (
          <div className="overflow-hidden lg:rounded-[45px] rounded-[24px] bg-orderbtn">
            <div
              className={`grid grid-cols-1 ${company?.footerScreenshot !== "" ? "xl:grid-cols-[510px_1fr]" : ""}`}
            >
              {!!company?.footerScreenshot && (
                <div className="first-column relative hidden overflow-hidden xl:flex">
                  <div className="relative h-[420px] w-full self-end">
                      <div className="absolute left-[160px] bottom-[-300px] z-0">
                        <img
                          className="rotate-[-8deg]"
                          src={company.footerScreenshot}
                          alt="Мобильная версия сайта"
                          width={300}
                          height={650}
                        />
                      </div>
                      <div className="absolute left-[53px] bottom-0 z-10">
                        <Smartphone />
                      </div>

                  </div>
                </div>
              )}
              {!!company?.template?.appMobileTitle && (
                <div className="second-column px-4 py-5 md+:pb-[45px] md+:pl-[46px] md+:pt-[50px]">
                  <p
                    className={`mb-[5px] text-center text-xl font-normal md+:mb-[10px] ${company?.footerScreenshot !== "" ? "xl:text-left" : ""} lg:text-5xl lg:font-medium`}
                  >
                    {company.template.appMobileTitle}
                  </p>
                  {!!company?.template?.appMobileText && (
                    <p
                      className={`text-center text-base font-normal text-main ${company?.footerScreenshot !== "" ? "xl:text-left" : ""} xl:text-[22px]`}
                    >
                      {company.template.appMobileText}
                    </p>
                  )}
                  <div
                    className={`mt-5 flex justify-center gap-x-[122px] xl:mt-[35px] ${company?.footerScreenshot !== "" ? "xl:justify-start" : ""}`}
                  >
                    <div className="w-full md+:w-auto">
                      {!!company?.iosAppLink && (
                        <a
                          href={company.iosAppLink}
                          onClick={() => metricsEvent("app-icon")}
                          target="_blank"
                          className="mb-[15px] block md+:mb-[11px]"
                          rel="noreferrer"
                        >
                          <BaseButton className="w-full bg-white md+:h-[44px] h-[50px]">
                            <div className="w-[107px]">
                              <AppStore />
                            </div>
                          </BaseButton>
                        </a>
                      )}
                      {!!company?.androidAppLink && (
                        <a
                          href={company.androidAppLink}
                          onClick={() => metricsEvent("app-icon")}
                          target="_blank"
                          className="mb-[15px] block md+:mb-[11px]"
                          rel="noreferrer"
                        >
                          <BaseButton className="w-full bg-white md+:w-auto md+:px-[99px] md+:py-[5px]">
                            <GooglePlay />
                          </BaseButton>
                        </a>
                      )}
                      {!!company?.rustoreAppLink && (
                        <a
                          href={company.rustoreAppLink}
                          onClick={() => metricsEvent("app-icon")}
                          target="_blank"
                          className="block"
                          rel="noreferrer"
                        >
                          <BaseButton className="w-full bg-white md+:w-auto md+:px-[99px] md+:py-[5px]">
                            <RuStore />
                          </BaseButton>
                        </a>
                      )}
                    </div>
                    {!!company?.footerQrCodeLink && (
                      <div className="hidden md+:block">
                        <div className="bg-white p-4 rounded-[13px]">
                          <QRCode value={company.footerQrCodeLink} size={109} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div>
          <div className="grid lg:mt-[30px] pb-[30px] lg:pb-[49px]">
            <div className="gap-x-[minmax(0, auto)] grid grid-cols-1 gap-y-5 py-5 lg:grid-cols-[260px_269px_164px_141px] lg:gap-x-[calc((100%_-_260px_-_269px_-_164px_-_141px_)/3)] lg:pt-[21px] lg:pb-[40px]">
              <div className="bg-transparent text-center lg:text-left">
                {branch?.contacts?.at(0)?.phone && (
                  <div>
                    <h2 className="mb-[6px] text-[22px] font-medium md+:text-3xl">
                      Связаться с нами
                    </h2>
                    <Link
                      to={`tel:${branch?.contacts?.at(0)?.phone}`}
                      className="no-underline"
                    >
                      <p className="text-sm font-medium text-main md+:text-xl md+:font-normal md+:text-gray-60 md+:dark:text-white">
                        {branch?.contacts?.at(0)?.phone}
                      </p>
                    </Link>
                  </div>
                )}
              </div>
              <div className="bg-transparent">
                <div className="grid grid-cols-3 grid-rows-2 gap-x-[30px] gap-y-[10px] lg:border-none border-y-[1px] border-y-[#efefef] dark:border-y-darkGray px-[29px] py-[20px] text-sm font-medium lg:grid-cols-[auto_auto] lg:grid-rows-3 lg:gap-x-[100px] lg:gap-y-[16px]  lg:px-0 lg:py-0 lg:text-xl">
                  <div className="order-3 md+:order-1">
                    <Link to={`/${city.slug}/about`}>О нас</Link>
                  </div>
                  <div className="order-2 md+:order-2">
                    <Link to={`/${city.slug}/stock`}>Акции</Link>
                  </div>

                  <div className="order-6 md+:order-3">
                    <Link to={`/${city.slug}/payment`}>Доставка</Link>
                  </div>
                  <div className="order-4 md+:order-4">
                    <Link to={`/${city.slug}`}>Каталог</Link>
                  </div>

                  <div className="order-5 md+:order-5">
                    <Link to={`/${city.slug}/reviews`}>Отзывы</Link>
                  </div>
                  <div className="order-1 md+:order-6">
                    <Link to={`/${city.slug}/payment`}>Оплата</Link>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center bg-transparent lg:items-start gap-[10px] lg:flex-col">
                {!!yandexRating && (
                  <div className="h-[55px] w-[150px] rounded-[5px] bg-yandexRating p-[10px] dark:bg-gray-40">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center text-base font-medium">
                        <span className="mr-1">
                          {!(theme === "dark") && <YandexIcon />}
                          {theme === "dark" && <YandexWhiteIcon />}
                        </span>
                        <span className="inline-block w-[4ch]">
                          {!yandexRating ||
                          Number(yandexRating).toFixed(2) === "0.00"
                            ? ""
                            : Number(yandexRating).toFixed(2)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        {[...Array(5)].map((item) => (
                          <StarRatingIcon key={item} />
                        ))}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[8px] font-normal">
                      <p>Рейтинг организации в Яндексе</p>
                    </div>
                  </div>
                )}
                <GisWidget/>
              </div>

              {!!branch?.telegramLink || !!branch?.vkCommunities?.at(0) ? (
                <div className="flex items-center justify-between gap-x-[35px] rounded-3xl bg-orderbtn pb-[14px] pl-[21px] pr-[14px] pt-[15px] dark:bg-orderbtn lg:items-start lg:justify-end lg:bg-transparent lg:px-0 lg:py-0 lg:dark:bg-transparent">
                  <div className="block flex-grow lg:hidden">
                    <h3 className="text-base font-medium">
                      Мы в социальных <br /> сетях
                    </h3>
                  </div>
                  <div className="flex items-center justify-between gap-x-[10px] lg:gap-x-[16px]">
                    {!!branch?.telegramLink && (
                      <a
                        href={branch.telegramLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TelegramRoundIcon className="w-[35.76px] h-[35.76px] md+:w-[62px] md+:h-[62px]" />
                      </a>
                    )}
                    {!!branch?.vkCommunities?.at(0) && (
                      <a
                        href={`https://vk.com/club${branch?.vkCommunities?.at(0)?.groupId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <VkRoundIcon className="w-[35.76px] h-[35.76px] md+:w-[62px] md+:h-[62px]" />
                      </a>
                    )}
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="mb-[31px] grid grid-cols-1 gap-y-5 lg:mb-[21px] lg:grid-cols-[243px_1fr_183px] lg:gap-y-0">
              <div className="order-2 bg-transparent lg:order-1">
                <div className="flex flex-col items-center text-[13px] lg:items-start xl:text-base">
                  <a
                    href={`/${city.slug}/personalDataPolicy`}
                    target="_blank"
                    className="text-main underline"
                    rel="noreferrer"
                  >
                    Политика конфиденциальности
                  </a>
                  <a
                    href={`/${city.slug}/termsOfUse`}
                    target="_blank"
                    className="text-main underline"
                    rel="noreferrer"
                  >
                    Пользовательское соглашение
                  </a>
                </div>
              </div>
              <div className="order-3 flex items-end justify-center bg-transparent lg:order-2 px-5">
                <p className="text-[10px] font-normal text-footerBlack dark:text-footerWhite lg:text-base text-center">
                  Copyright &copy; {new Date().getFullYear()} «{company.title}
                  ». Все права защищены
                </p>
              </div>

              <div className="order-1 bg-transparent text-center lg:order-3 lg:text-right">
                <div className="text-base font-normal dark:text-gray-10">
                  Работает на технологии
                </div>
                {company?.template?.theme === "dark" && (
                  <Link
                    to={"/"}
                    target="_blank"
                    className="flex justify-center lg:justify-end"
                  >
                    <img src={logoFooter2} className="" alt="" />
                  </Link>
                )}
                {company?.template?.theme === "light" && (
                  <Link
                    to={"/"}
                    target="_blank"
                    className="flex justify-center lg:justify-end"
                  >
                    <img src={logoFooter2Light} className="" alt="" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
