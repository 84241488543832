import { useEffect, useState } from "react";
import { useCartContext } from "../../../contexts/CartContext";
import { useVisitorContext } from "../../../contexts/VisitorContext";
import { useAppContext } from "../../../contexts/App";
import { formatAddress } from "../../../libs/helpers/formatAddress";


export default function AddressClarificationPopup({
  address,
  openSelectAddress,
  isTimeDialogOpen
}: any) {
  const { openTimeInfo, addressClarificationPopupShow, setAddressClarificationPopupShow } = useAppContext()

  const { popupsData, closedPopups } = useVisitorContext();
  const { cart } = useCartContext();
  const [show, setShow] = useState(false);
  const [point, setPoint] = useState(false);


  useEffect(() => {
    if (typeof window !== "undefined") {
      let popups = Object.values(
        popupsData.reduce(
          (acc: any, cur: any) => ({ ...acc, [cur.uid]: cur }),
          {}
        )
      ).filter((action: any) => !closedPopups.includes(action.uid));

      if (
        !addressClarificationPopupShow &&
        popups.length === 0 &&
        cart.cartId &&
        openTimeInfo &&
        !isTimeDialogOpen
      ) {
        setAddressClarificationPopupShow(true);
        if (address?.point) {
          setPoint(address.point);
          setShow(true);
        }
      }
    }
  }, [
    address,
    popupsData,
    cart.cartId,
    openTimeInfo,
    isTimeDialogOpen,
    closedPopups
  ]);

  useEffect(() => {
    if (addressClarificationPopupShow) {
      setShow(false);
    }
  }, [address]);

  return (
    <div
      className={`absolute right-0 md:top-[55px] top-0 z-[200] ${!show && "hidden"} min-w-[100%] md:rounded-t-[30px] rounded-b-[30px] shadow-md md:min-w-[280px] bg-white dark:bg-lightBlack p-[15px]`}
    >
      <div className="md:text-[14px] text-center text-normalGray dark:text-gray-30 mb-[5px]">
        Заказ на этот адрес?
      </div>
      <div className="text-center font-medium mb-[30px] md:px-[30px] px-[22px] text-dark dark:text-light  ">
        {formatAddress(point, ["city"])}
        {", "}
        {formatAddress(point, ["house", "street", "block"], true)}
      </div>
      <div className="flex gap-[10px]">
        <div
          onClick={openSelectAddress}
          className="w-1/2 bg-orderbtn text-main font-medium h-[45px] flex items-center justify-center rounded-[29px] cursor-pointer"
        >
          Нет
        </div>
        <div
          className="w-1/2 bg-main text-white font-medium h-[45px] flex items-center justify-center rounded-[29px] cursor-pointer"
          onClick={() => setShow(false)}
        >
          Да
        </div>
      </div>
    </div>
  );
}
