import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../../../contexts/App";
import { useAddressContext } from "../../../../../contexts/AddressContext";
import { useInputSelectorDadata } from "../../../form/input/InputSelectorDadata";
import { formatAddress } from "../../../../../libs/helpers/formatAddress";
import { apiClient } from "../../../../../libs/api/apiClient";
import ArrowIcon from "../../../../icons/ArrowIcon";
import { TextInput } from "../../../form/input/TextInput";

export default function ScreenSearchDelivery({
  isActive,
  close,
  deliveryAddress,
  newCity,
  selectDeliveryAddress
}: any) {
  const { company } = useAppContext()
  const { allUserAddresses } = useAddressContext();
  const [query, setQuery] = useState(deliveryAddress?.name || "");

  useEffect(() => {
    setQuery(deliveryAddress?.name || "");
  }, [isActive]);

  const inputRef = useRef<any>(null);

  useEffect(() => {
    isActive && inputRef?.current?.focus();
  }, [isActive]);

  const { variants } = useInputSelectorDadata({
    makeVariants: ({ data, deliveryZone, hasDeliveryZone }: any) =>
      data?.map(({ addressId, ...o }: any, i: number) => ({
        id: i,
        addressId,
        name: formatAddress(o, ["city"]),
        addr: { ...o, deliveryZone, hasDeliveryZone }
      })),
    sendRequest: (query: string, options: any) => {
      if (
        query.trim() === "" ||
        formatAddress(allUserAddresses[0]?.address, [""]) === query
      ) {
        return new Promise((resolve) => {
          resolve(
            allUserAddresses
              // .filter((o : any) => !filterUserAddresses || guids.length === 0 || guids.includes(o.city?.guid))
              .map((o: any) => ({ ...o, ...o.address, addressId: o.id }))
          );
        });
      }
      return apiClient.suggestions.getAddressSuggestions(
        query,
        newCity ? [newCity.guid] : [],
        newCity ? newCity.lat : null,
        newCity ? newCity.lon : null,
        options,
        company.id
      );
    },
    // defaultQuery: deliveryAddress?.name,
    requestDeps: [allUserAddresses],
    query,
    setQuery
  });

  return (
    <div className={`relative ${!isActive && "hidden"}`}>
      <div className="fixed w-[100vw] h-[100vh] bg-[#fff] dark:bg-dark z-[1000] top-0 left-0 py-5 px-[15px] overflow-y-hidden">
        <div
          onClick={close}
          className="flex items-center gap-x-[15px] fomt-medium cursor-pointer"
        >
          <ArrowIcon className="w-[13px] h-[13px] rotate-180" />
          Назад
        </div>

        <TextInput
          className="my-5"
          value={query}
          onChange={setQuery}
          inputRef={inputRef}
          placeholderInput={"Введите улицу и дом"}
        />

        <div
          className="overflow-y-auto no-scroll-bar"
          style={{ height: "calc(100vh - 150px)" }}
        >
          {!!query &&
            variants.map(({ name, id, addr }: any) => (
              <div
                key={id + ""}
                onClick={() => {
                  setQuery(name);
                  selectDeliveryAddress({ ...addr, name });
                  close();
                }}
                className="p-[15px] border-[#E1E1E1] dark:border-normalGray border-b-[1px] cursor-pointer"
              >
                <div className="text-darkGray dark:text-white leading-[18px]">
                  {name}
                </div>
                <div className="text-normalGray text-[14px] lines-ellipsis leading-4">
                  {formatAddress(addr, ["house", "street", "block"], true)}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
