import { useEffect, useRef, useState } from "react";
import GeomarkIcon from "../../../../icons/GeomarkIcon";
import ArrowIcon from "../../../../icons/ArrowIcon";

export default function CitySelector({
  cities,
  value,
  onChange
}: any) {
  const [active, setActive] = useState(false);
  const ref: any = useRef(null);

  // useEffect(() => {
  //   function handleClick(event: any) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setActive(false);
  //     }
  //   }

  //   if (active) document.addEventListener("mousedown", handleClick);

  //   return () => {
  //     if (active) document.removeEventListener("mousedown", handleClick);
  //   };
  // }, [active, setActive, cities.length, ref]);

  return (
    <>
      <div
        className={`flex items-center gap-x-[10px] h-[35px] cursor-pointer md+:relative absolute md+:top-0 md+:left-0 top-[15px] left-[15px] bg-white md:bg-[#F5F5F5] dark:bg-dark md:dark:bg-dark md+:p-0 py-[8px] px-[10px] rounded-[29px]`}
        onClick={() => setActive(!active)}
      >
        <GeomarkIcon width="16" height="16" />
        <span className="text-main -tracking-[0.16px]">
          {cities[value]?.title}
        </span>
        <div className={`${active ? "" : "mt-[5px]"}`}>
          <ArrowIcon className={`w-[8px] h-[13px] ${active ? "-rotate-90" : "rotate-90"} `} />
        </div>
      </div>
      {active && (
        <div className="h-[24px] md+:relative absolute md+:top-0 md+:left-0 top-[55px] left-[15px] rounded-[29px]">
          <div
            className="absolute top-0 left-0 bg-white dark:bg-dark shadow-[0px_7px_12px_-3px_#0000001A] rounded-[10px] z-[10] max-h-[228px] overflow-y-auto pretty-scroll4"
            ref={ref}
          >
            <div
              className="cursor-pointer h-[38px] py-[7px] pl-[15px] pr-[20px] select-none active:text-main active:bg-orderbtn"
              onClick={() => setActive(!active)}
            >
              {cities[value]?.title}
            </div>

            {Object.entries(cities)
              .filter(([slug, city]) => slug !== value)
              .map(([slug, city]: any) => (
                <div
                  key={slug}
                  className="relative h-[38px] py-[7px] pl-[15px] pr-[20px] cursor-pointer select-none active:text-main active:bg-orderbtn after:absolute after:top-0 after:left-[12px] after:h-[1px] after:w-[calc(100%-24px)] after:bg-[#E1E1E1] after:dark:bg-darkGray after:content-['']"
                  onClick={() => {
                    onChange(slug);
                    setActive(!active);
                  }}
                >
                  {city.title}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
