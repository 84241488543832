import useForm from "../../../hooks/useForm";
import { apiClient } from "../../../libs/api/apiClient";
import { IQuestions } from "../../../pages/[city]/account/history";
import { BaseButton } from "../../common/button/BaseButton";
import { CustomBoolSlider } from "../../common/customBoolSlider";
import { Preloader } from "../../common/preloader";
import StarRating from "../../common/starRating";
import { Input } from "./inputsThirdStep/input";
import { TextareaForThirdStepReviews } from "./inputsThirdStep/textarea";
import { useState } from 'react'

interface thirdStepProps {
  maxLengthTextarea: number;
  inPopup?: boolean;
  questions: IQuestions[] | null | undefined;
  nextStep: () => void;
  questionnaireId: any;
}

export default function ThirdStep({
  maxLengthTextarea,
  inPopup = false,
  questions,
  nextStep,
  questionnaireId
}: thirdStepProps) {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const defaultData = questions
    ?.map((question) => {
      if (
        question.answerType === "ten-point-scale" ||
        question.answerType === "five-point-scale"
      ) {
        return { npsQuestionId: question.id, answer: 1 };
      }
      if (question.answerType === "bool") {
        return { npsQuestionId: question.id, answer: "yes" };
      }
      if (question.isRequired) {
        return { npsQuestionId: question.id, answer: null };
      }
    })
    .filter((question) => question);

  const { data, updateField } = useForm({ answers: defaultData });

  const handleSubmit = async () => {
    setLoading(true);
    await apiClient.profileOrders
      .addAnswers(questionnaireId, data)
      .then((res) => {
        setLoading(false);
        if (res.status === 422) {
          setErrors(res.errors);
        } else {
          nextStep();
        }
      });
  };

  return (
    <div>
      <div
        className={`flex flex-col ${inPopup ? "gap-y-[30px]" : "xs:gap-y-10 gap-y-[25px]"} `}
      >      
        {questions?.map((question) => (
          <div key={question.id}>
            <h3
              className={`font-bold ${inPopup ? "mb-[20px] xs:text-xl text-lg" : "mb-[25px] xs:text-2xl text-[22px]"}`}
            >
              {question.text}
              <span className="ml-1 text-red-700">
                {question.isRequired && "*"}
              </span>
            </h3>
            {question.answerType === "single-line-text" && (
              <Input id={question.id} answers={data.answers} errors={errors} />
            )}

            {question.answerType === "multiline-text" && (
              <TextareaForThirdStepReviews
                id={question.id}
                answers={data.answers}
                maxLength={maxLengthTextarea}
                errors={errors}
              />
            )}

            {question.answerType === "ten-point-scale" && (
              <StarRating
                fieldName={`answers.${question.id}.answer`}
                updateField={updateField}
                errors={errors}
                distanceBetweenElements={window.innerWidth > 768 ? (inPopup ? 10 : 20) : 4}
                count={10}
                height={32}
                width={32}
              />
            )}

            {question.answerType === "five-point-scale" && (
              <StarRating
                fieldName={`answers.${question.id}.answer`}
                updateField={updateField}
                errors={errors}
                distanceBetweenElements={inPopup ? 10 : 20}
                height={window.innerWidth > 768 ? 32 : 40}
                width={window.innerWidth > 768 ? 32 : 40}
              />
            )}

            {question.answerType === "bool" && (
              <CustomBoolSlider
                inPopup={inPopup}
                answers={data.answers}
                id={question.id}
              />
            )}
          </div>
        ))}

        <div
          className={`${inPopup ? "sticky bottom-0 bg-white rounded-[18px] mt-[30px]" : "mt-[30px] md:mt-[50px]"}`}
        >
          <BaseButton
            disabled={loading}
            className="bg-orderbtn w-full text-main font-bold"
            onClick={handleSubmit}
          >
            {loading ? (
              <Preloader
                countOfDot={4}
                size="10px"
                className="h-[20px] gap-2 items-center"
              />
            ) : (
              "Оставить отзыв"
            )}
          </BaseButton>
        </div>
      </div>
    </div>
  );
}
