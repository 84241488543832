import { useEffect, useState } from "react";
import { IGoods } from "../../../pages/[city]/account/history";
import useForm from "../../../hooks/useForm";
import { apiClient } from "../../../libs/api/apiClient";
import { BaseButton } from "../../common/button/BaseButton";
import { Preloader } from "../../common/preloader";
import StarRating from "../../common/starRating";

interface secondStepProps {
  inPopup?: boolean;
  goods: IGoods[] | null | undefined;
  nextStep: () => void;
  questionnaireId: any;
}

export default function SecondStep({
  inPopup = false,
  goods,
  nextStep,
  questionnaireId
}: secondStepProps) {
  const { data } = useForm({
    goodsReviews: goods?.map((good) => ({ goodId: good.id, estimation: 1 }))
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    await apiClient.profileOrders
      .addGoodsReview(questionnaireId, data)
      .then((res) => {
        setLoading(false);
        if (res.status === 422) {
          setErrors(res.errors);
        } else {
          nextStep();
        }
      });
  };

  return (
    <div className={`flex flex-col ${inPopup ? "" : ""}`}>
      {goods?.map((good) => (
        <div
          key={good.id}
          className={`bg-white ${inPopup ? "mb-[20px] dark:bg-transparent" : "mb-[20px] rounded-[29px] p-[15px] shadow-md dark:bg-gray-40 xs:p-[15px] md:mb-[30px]"}`}
        >
          <div className="flex">
            <div
              className={`${inPopup ? "h-[86px] min-w-[82px] max-w-[82px] rounded-[17px] dark:bg-gray-40" : "h-[90px] min-w-[90px] max-w-[90px] rounded-[16px] md:h-[120px] md:min-w-[120px] md:max-w-[120px]"} overflow-hidden bg-gray-50`}
            >
              <img
                src={good.image}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
            <div
              className={`${inPopup ? "ml-[15px]" : "ml-[20px]"} flex flex-col justify-between`}
            >
              <div className="">
                <h4
                  className={`word-break ${inPopup ? "text-lg" : "text-[18px] md:text-[20px]"} font-medium`}
                >
                  {good.title}
                </h4>
                <div />
              </div>
              <div className="">
                {/* <Range min={1} max={5} goodsReviews={data.goodsReviews} goodId={good.id} errors={errors} /> */}
                <StarRating
                  fieldName="estimation"
                  goodsReviews={data.goodsReviews}
                  goodId={good.id}
                  errors={errors}
                  errorLabel
                  distanceBetweenElements={
                    inPopup ? 15 : isSmallScreen ? 10 : 20
                  }
                  width={inPopup ? 35 : isSmallScreen ? 35 : 34}
                  height={inPopup ? 35 : isSmallScreen ? 35 : 32}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      <div
        className={`${inPopup ? "sticky bottom-0 left-0 z-[20] rounded-[18px] bg-white" : "mt-[10px]"}`}
      >
        <BaseButton
          disabled={loading}
          className="w-full bg-orderbtn text-main"
          onClick={handleSubmit}
        >
          {loading ? (
            <Preloader
              countOfDot={4}
              size="10px"
              className="h-[20px] items-center gap-2"
            />
          ) : (
            "Оставить отзыв"
          )}
        </BaseButton>
      </div>
    </div>
  );
}
