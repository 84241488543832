import React, {useState, useEffect} from "react";
import { formatDate } from "../../../../libs/helpers/formatDate";
import { useAppContext } from "../../../../contexts/App";
import { Link, useNavigate } from "react-router-dom";
import { BaseButton } from "../../../../components/common/button/BaseButton";
import { useCartContext } from "../../../../contexts/CartContext";
import { usePagination } from "../../../../hooks/usePagination";
import { apiClient } from "../../../../libs/api/apiClient";
import PopupActionSheet from "../../../../components/common/popup/PopupActionSheet";
import { useUpdatePage } from "../../../../hooks/useUpdatePage";
import { Preloader } from "../../../../components/common/preloader";
import DirtyCart from "../../../../components/common/popup/other/DirtyCart";
import { ProfileLayout } from "../../../../components/layouts/profile";
import FeedbacksPopupContent from "../../../../components/pages/feedbacksPage/feedbacksPopupContent";
import { useMetricsContext } from "../../../../contexts/MetricsContext";
import OrderPopup from "../../../../components/pages/profile/history/OrderPopup";


interface IHistoryCard {
  order: IOrder;
  city: string;
  repeatOrderWithConfirm: (v: number) => void;
  fetchOrder: () => void;
  setActiveOrderPopup: (id: number) => void;
}

interface IOrder {
  id: number;
  createdAt: string;
  status: string;
  total: number | null;
  review: {
    status: string;
    estimation: number;
  } | null;
  address: { title: string } | null;
  pickupPoint: {
    address: {
      title: string;
    };
  } | null;
  flat: string | null;
  entrance: string | null;
  floor: number | null;
  questionnaire: IQuestionnaire;
  goodReviewCreatedAt: string | null;
  orderReviewCreatedAt: string | null;
  questionAnswerCreatedAt: string | null;
  payment: {
    isPayed: boolean;
    paymentLink: string | null;
  };
  paymentType: string;
  statusTraces: { status: string }[];
}

export interface IQuestions {
  id: number;
  isRequired: boolean;
  text: string;
  answerType: string;
}

export interface IGoods {
  id: number;
  image: string;
  title: string;
}

export interface IQuestionnaire {
  id: string;
  orderId: number;
  questions: IQuestions[];
  goods: IGoods[];
  hasOrderReview: boolean;
  hasGoodsReviews: boolean;
  hasQuestionsAnswers: boolean;
  orderReviewCreatedAt: string;
  goodReviewCreatedAt: string;
  questionAnswerCreatedAt: string;
}

export function HistoryCard({
  order,
  city,
  repeatOrderWithConfirm,
  fetchOrder,
  setActiveOrderPopup
}: IHistoryCard) {
  const [isOpenModalFeedbacks, setIsOpenModalFeedbacks] = useState(false);
  const { resDate, resTime } = formatDate(new Date(order.createdAt));
  const { company, branch } = useAppContext()
  const { metricsEvent } = useMetricsContext();

  const isShowFeedbackBtn =
    !order?.orderReviewCreatedAt ||
    (!order?.goodReviewCreatedAt && order?.questionnaire?.goods.length) ||
    (!order?.questionAnswerCreatedAt && order?.questionnaire?.questions.length);

  const orderStatus = company.statuses[order.status];

  return (
    <div className="bg-white rounded-[20px] shadow-md py-2 px-6 hover:shadow-xl dark:bg-gray-50">
      <div onClick={() => setActiveOrderPopup(order.id)}>
        <div className="flex sm:flex-row flex-col gap-5 sm:justify-between sm:items-center py-2 pb-2 cursor-pointer border-b dark:border-gray-40">
          <div className="flex flex-col md:flex-row gap-y-2 sm:items-center gap-x-4">
            <div className="flex xs:gap-x-4 xs:text-xl text-lg gap-x-2">
              <p className="font-bold">Заказ {order.id}</p>
              <p className="font-medium">{resDate}</p>
              <p className="font-medium">{resTime}</p>
            </div>
          </div>

          {order?.review?.estimation && (
            <div className="flex gap-2 mr-auto">
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Star
                    key={index}
                    className={
                      index < (order?.review?.estimation || 0)
                        ? "fill-yellow"
                        : "fill-gray-30"
                    }
                  />
                ))}
            </div>
          )}

          {orderStatus && (
            <div style={{ color: orderStatus.color }}>{orderStatus.title}</div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-y-1 mt-2 mb-5">
        {order.pickupPoint && (
          <p className="text-gray-50 dark:text-white">
            {order.pickupPoint.address.title}{" "}
          </p>
        )}
        {order.address && (
          <p className="text-gray-50 dark:text-white">
            {order.address?.title}
            <span className="ml-[5px]">
              {order.entrance && (
                <span className="mr-[3px]">{`под ${order.entrance},`}</span>
              )}
              {order.floor && (
                <span className="mr-[3px]">{`эт ${order.floor},`}</span>
              )}
              {order.flat && (
                <span className="mr-[3px]">{`кв ${order.flat}`}</span>
              )}
            </span>
          </p>
        )}

        <div className="flex flex-row items-center gap-x-2 text-lg font-bold">
          <p>Сумма заказа: </p>
          <p className="text-main">{order.total} ₽</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 mb-4 sm:gap-x-3 sm:flex-row w-full justify-between">
        <div className="flex w-full gap-x-3 sm:gap-x-3 sm:w-full xss:flex-row flex-col xss:gap-y-0 gap-y-2">
          {!!(
            order.status === "completed" &&
            isShowFeedbackBtn &&
            order.questionnaire
          ) && (
            <BaseButton
              onClick={() => setIsOpenModalFeedbacks(true)}
              className="sm:w-1/3 w-full md+:text-sm text-xs bg-orderbtn text-main hover:bg-main hover:text-white font-medium break-words"
            >
              Оставить отзыв
            </BaseButton>
          )}
          {order.status === "accepted" && (
            <a
              href={`tel:${branch.contacts[0].phone}`}
              onClick={() => metricsEvent("phone")}
              className="disabled:cursor-default font-medium flex justify-center items-center transition duration-300 ease-out text-sm px-[22px]  rounded-[18px] sm:w-1/3 w-full md+:text-sm text-xs bg-orderbtn text-main hover:bg-main hover:text-white font-medium break-words"
            >
              {" "}
              <BaseButton className=""> Связаться с нами</BaseButton>
            </a>
          )}
          {(order.status === "completed" || order.status === "cancelled") && (
            <BaseButton
              onClick={() => repeatOrderWithConfirm(order.id)}
              className="sm:w-1/3 w-full md+:text-sm text-xs bg-orderbtn text-main hover:bg-main hover:text-white font-medium break-words"
            >
              Повторить заказ
            </BaseButton>
          )}

          {order.paymentType === "online" &&
            !order?.payment?.isPayed &&
            order?.payment?.paymentLink && (
              <a href={order?.payment?.paymentLink} className="sm:w-1/3 w-full">
                <BaseButton className="w-full md+:text-sm text-xs bg-green-200 bg-orderbtn text-main hover:bg-main hover:text-white font-medium whitespace-nowrap">
                  Оплатить заказ
                </BaseButton>
              </a>
            )}
        </div>
      </div>

      <PopupActionSheet
        closeIcon
        classNamePopup="md:w-[470px]"
        isActive={isOpenModalFeedbacks}
        close={() => {
          fetchOrder();
          setIsOpenModalFeedbacks(false);
        }}
      >
        {isOpenModalFeedbacks && (
          <FeedbacksPopupContent
            questionnaire={{ ...order, ...order?.questionnaire }}
          />
        )}
      </PopupActionSheet>
    </div>
  );
}

export default function MyHistory() {
  const { cart, repeatOrder } = useCartContext();
  const { city } = useAppContext()

  const [isDirtyCart, setDirtyCart] = useState<boolean>(false);
  const [isOpenRedirectModal, setIsOpenRedirectModal] = useState(false);
  const [activeOrderPopup, setActiveOrderPopup] = useState<any>(null);
  const navigate = useNavigate()

  // -------------pagination
  const {
    allData,
    nextPageAction,
    updateDataAction,
    updateItemAction,
    isFetching,
    isInitialLoading
  } = usePagination(
    async (limit, page) => apiClient.profileOrders.getOrders(limit, page),
    {
      identity: (item) => item.id,
      startingPage: 1
    }
  );
  useEffect(() => {
    const scrollHandler = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollHeight - (scrollTop + clientHeight) < 250) {
        nextPageAction();
      }
    };
    document.addEventListener("scroll", scrollHandler);
    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  // --------------updating
  useUpdatePage(
    () => {
      console.log("-------------------Update Orders-------------------");
      updateDataAction(5);
    },
    { interval: 10000 }
  );

  useEffect(() => {
    if (cart.products?.length > 0) {
      setDirtyCart(true);
    } else setDirtyCart(false);
  }, [cart.products]);

  const [repeatOrderModal, setRepeatOrderModal] = useState<number>(-1);

  const repeatOrderAndGoToCart = async (idOrder: number) => {
    if (await repeatOrder(idOrder)) setIsOpenRedirectModal(true);
  };

  const repeatOrderWithConfirm = async (idOrder: number) => {
    if (isDirtyCart) {
      setRepeatOrderModal(idOrder);
    } else {
      await repeatOrderAndGoToCart(idOrder);
    }
  };

  return (
    <ProfileLayout>
      <div className="w-full flex gap-y-10">
        <div className="w-full flex flex-col gap-y-7">
          <p className="text-2xl font-medium">История заказов</p>
          {allData &&
            allData.map((order, index) => (
              <HistoryCard
                setActiveOrderPopup={setActiveOrderPopup}
                repeatOrderWithConfirm={repeatOrderWithConfirm}
                key={index}
                order={order}
                city={city.slug}
                fetchOrder={() => updateItemAction(index)}
              />
            ))}
          {!isInitialLoading && isFetching && (
            <Preloader countOfDot={4} size="10px" />
          )}
          {isInitialLoading && (
            <div className="w-full py-[20vh]">
              <Preloader countOfDot={4} size="10px" />
            </div>
          )}

          <OrderPopup
            repeatOrderWithConfirm={repeatOrderWithConfirm}
            orderId={activeOrderPopup}
            close={() => setActiveOrderPopup(null)}
          />

          <DirtyCart
            repeatOrderId={repeatOrderModal}
            close={() => setRepeatOrderModal(-1)}
            setIsOpenRedirectModal={setIsOpenRedirectModal}
          />

          <PopupActionSheet
            closeIcon
            isActive={isOpenRedirectModal}
            close={() => setIsOpenRedirectModal(false)}
            classNamePopup="w-[550px]"
          >
            <p className="text-2xl font-bold text-center">Перейти в корзину?</p>
            <div className="flex xs:flex-row flex-col justify-between mt-5">
              <BaseButton
                onClick={() => {
                  navigate(`/${city.slug}/cart`)
                }}
                className="bg-main text-white hover:opacity-80 font-bold xs:w-[48%]"
              >
                Да
              </BaseButton>
              <BaseButton
                onClick={() => setIsOpenRedirectModal(false)}
                className="bg-orderbtn text-main font-bold hover:bg-main hover:text-white xs:w-[48%] xs:mt-0 mt-3"
              >
                Продолжить покупки
              </BaseButton>
            </div>
          </PopupActionSheet>
        </div>
      </div>
    </ProfileLayout>
  );
}

function Star({ className = "fill-gray-30" }) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.07031 0L10.4508 4.22348L15.2032 5.18237L11.9221 8.75152L12.4787 13.5676L8.07031 11.55L3.66192 13.5676L4.21853 8.75152L0.937388 5.18237L5.68978 4.22348L8.07031 0Z" />
    </svg>
  );
}