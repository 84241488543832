import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../../../contexts/App";
import { useAddressContext } from "../../../../../contexts/AddressContext";
import { formatAddress } from "../../../../../libs/helpers/formatAddress";
import { apiClient } from "../../../../../libs/api/apiClient";
import { TextInput } from "../../../form/input/TextInput";
import CloseIcon from "../../../../icons/CloseIcon";
import { useInputSelectorDadata } from "../../../form/input/InputSelectorDadata";


export default function AddressSearch({
  newCity,
  deliveryAddress,
  selectDeliveryAddress,
  clear
}: any) {
  const { company } = useAppContext()
  const { allUserAddresses } = useAddressContext();
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  const ref: any = useRef(null);

  useEffect(() => {
    setQuery(deliveryAddress?.name || "");
  }, [deliveryAddress?.name]);

  useEffect(() => {
    function handleClick(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    if (show) document.addEventListener("mousedown", handleClick);
    return () => {
      if (show) document.removeEventListener("mousedown", handleClick);
    };
  }, [show, setShow, ref]);

  const { variants } = useInputSelectorDadata({
    makeVariants: ({ data, deliveryZone, hasDeliveryZone }: any) =>
      data?.map(({ addressId, ...o }: any, i: number) => ({
        id: i,
        addressId,
        name: formatAddress(o, ["city"]),
        nameFull: formatAddress(o, ["house", "street", "block"], true),
        addr: { ...o, deliveryZone, hasDeliveryZone }
      })),
    sendRequest: (query: string, options: any) => {
      if (
        query.trim() === "" ||
        formatAddress(allUserAddresses[0]?.address, [""]) === query
      ) {
        return new Promise((resolve) => {
          resolve(
            allUserAddresses
              // .filter((o : any) => !filterUserAddresses || guids.length === 0 || guids.includes(o.city?.guid))
              .map((o: any) => ({ ...o, ...o.address, addressId: o.id }))
          );
        });
      }
      return apiClient.suggestions.getAddressSuggestions(
        query,
        newCity ? [newCity.guid] : [],
        newCity ? newCity.lat : null,
        newCity ? newCity.lon : null,
        options,
        company.id
      );
    },
    // defaultQuery: deliveryAddress?.name,
    requestDeps: [allUserAddresses],
    query,
    setQuery
  });

  return (
    <div className="relative w-full" ref={ref}>
      <div
        className={`flex justify-between rounded-[10px] pl-[15px] ${show ? "bg-white dark:bg-gray-40" : "bg-gray-20 dark:bg-gray-40"}`}
      >
        <TextInput
          value={query}
          onChange={setQuery}
          onFocus={() => setShow(true)}
          placeholderInput="Введите улицу и дом"
          className="!px-0 bg-transparent dark:bg-transparent w-full py-[7px]"
        />
        <div
          onClick={() => {
            clear();
            setQuery("");
          }}
          className={`min-h-full px-[15px] flex items-center cursor-pointer ${!query && "hidden"}`}
        >
          <CloseIcon className="w-[10px] h-[10px]" colorClassName="fill-main" />
        </div>
      </div>

      <div
        className={`absolute top-[38px] rounded-[10px] left-0 w-full max-h-[300px] overflow-y-scroll bg-white dark:bg-gray-40 px-[10px] py-1 z-[10] pretty-scroll ${(!show || !variants.length) && "hidden"}`}
      >
        {variants.map((item: any, index: number) => (
          <div
            onClick={() => {
              setQuery(item.name);
              selectDeliveryAddress(item);
              setShow(false);
            }}
            className={`p-[10px] border-[#E1E1E1] dark:border-normalGray cursor-pointer ${index !== variants.length - 1 && "border-b-[1px]"}`}
            key={item.id}
          >
            {item.name ? (
              <>
                <div className="text-darkGray dark:text-white leading-[18px]">
                  {item.name}
                </div>
                <div className="text-normalGray text-[14px] lines-ellipsis leading-4">
                  {item.nameFull}
                </div>
              </>
            ) : (
              <div className="text-darkGray dark:text-white leading-[18px] mb-[14px]">
                {item.nameFull}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
