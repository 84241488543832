import React, { useEffect, useMemo, useState } from 'react'

import Search from './Search'
import Categories from './Categories'
import Popular from './Popular'
import ProductCard from './ProductCard'
import { useAppContext } from '../../../../contexts/App'
import { apiClient } from '../../../../libs/api/apiClient'
import { SVGWrap } from '../../../common/util/SVGWrap'
import { Preloader } from '../../../common/preloader'
import Footer from '../../../common/footer/footer'
import { ActiveCategoryRef, CatalogCategory, ProductTag } from '../../../../pages/[city]'
import { useWindowResize } from '../../../../hooks/useWindowResize'
import ProductCardVerticalTwoRow from './ProductCardVerticalTwoRow'

type Props = {
  catalog: Array<CatalogCategory>;
  setCatalog: (any: CatalogCategory[]) => void;
  tags: Array<ProductTag>;
  searchTags: any;
  setSearchTags: (value: any) => void;
  searchData: any;
  initialCatalog: any;
  searchCategoriesWrapper: any;
  activeCategoryRef: ActiveCategoryRef;
  activeCategoryIndex: number;
  noSearchResult: boolean;
  searchInHeader: boolean;
};

export default function Catalog(props: Props) {
  const {
    catalog,
    setCatalog,
    searchTags,
    setSearchTags,
    noSearchResult,
    searchData,
    initialCatalog,
    searchCategoriesWrapper,
    activeCategoryRef,
    activeCategoryIndex,
    tags,
    searchInHeader
  } = props;

  const { company, branch } = useAppContext()
  const isMobileScreen = useWindowResize({ cvt: (w) => w < 520 });

  const filterCatalogByTags = (id: number) => {
    const actuallyTags = searchTags.includes(id) ? searchTags.filter((tag: number) => tag !== id) : Array.from(new Set([...searchTags, id]))

    setSearchTags(actuallyTags)

    apiClient.catalog.get(branch.id, { byTags: actuallyTags, search: searchData.searchQuery }).then(({ data }: any) => {
      if (data) {
        setCatalog(data?.catalog || [])
      }
    })
  }

  return (
    <div className="mt-5">
      <CatalogCategoriesBlock
        searchData={searchData}
        catalog={catalog}
        setCatalog={setCatalog}
        initialCatalog={initialCatalog}
        searchCategoriesWrapper={searchCategoriesWrapper}
        activeCategoryRef={activeCategoryRef}
        activeCategoryIndex={activeCategoryIndex}
        searchInHeader={searchInHeader}
      />
      <div className="container overflow-x-hidden">
        <div
          className={`${searchData.searchQuery ? "hidden" : "opacity-100"} duration-200`}
        >
          <Popular />
        </div>

        <div
          className={`no-scroll-bar flex w-full items-center gap-10 overflow-x-auto ${
            searchData.searchQuery ? "mt-[20px]" : ""
          }`}
        >
          {tags.map((tag) => (
            <div
              key={tag.id}
              className="flex w-max cursor-pointer items-center gap-3"
              onClick={() => filterCatalogByTags(tag.id)}
            >
              <SVGWrap
                src={tag.icon}
                width="16px"
                height="16px"
                color={tag.color}
              />
              <div
                className="whitespace-nowrap font-semibold text-gray-50 dark:text-white"
                style={searchTags.includes(tag.id) ? { color: tag.color } : {}}
              >
                {tag.text}
              </div>
            </div>
          ))}
        </div>

        <div
          className={`min-h-[30vh] mt-5 ${
            noSearchResult && "flex items-center justify-center"
          }`}
        >
          {noSearchResult && (
            <div className="mt-[30px] flex flex-row justify-center text-sm dark:text-gray-10 xs:text-lg">
              <p>Товары не найдены</p>
            </div>
          )}

          {catalog ? (
            catalog.map((category: any) => {
              if (category.goods.length) {
                return (
                  <div
                    className="catalog-category mb-[35px] md:mb-10"
                    key={category.id}
                    id={`category_${category.id}`}
                  >
                    <div className="mb-5 text-2xl font-bold -tracking-[.01em] dark:text-white md:mb-10 md:text-4xl">
                      {category.title}
                    </div>
                    <div
                      className={`grid ${company.template.cartMobileOrientation === "verticalTwoRow" ? "grid-cols-2" : "grid-cols-1 xs:grid-cols-2"} md:grid-cols-3 md+:grid-cols-4 gap-[10px] md:gap-[30px]`}
                    >
                      {category.goods.map((productId: any, index: number) => (
                        <>
                          {isMobileScreen &&
                          company.template.cartMobileOrientation ===
                            "verticalTwoRow" ? (
                            <ProductCardVerticalTwoRow
                              key={productId}
                              productId={productId}
                              tags={tags}
                            />
                          ) : (
                            <ProductCard
                              key={productId}
                              productId={productId}
                              tags={tags}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <Preloader countOfDot={4} />
          )}
        </div>
      </div>
      <div className="mt-5" />
      <Footer />
    </div>
  )
}

function CatalogCategoriesBlock(props: any) {
  const {
    searchData,
    catalog,
    setCatalog,
    initialCatalog,
    searchCategoriesWrapper,
    activeCategoryRef,
    activeCategoryIndex,
    searchInHeader
  } = props;

  return (
    <div
      className={`sticky top-[66px] md:top-[60px] pt-[5px] pb-[15px] transition-[0.3] ${searchInHeader && "z-[100] bg-gray-10 dark:bg-gray-50 shadow-[0px_15px_35px_rgba(0,0,0,0.08)]"}`}
      ref={searchCategoriesWrapper}
    >
      <div className="container h-10 flex items-center">
        <div className="relative w-full">
          <Search
            className="absolute z-[100]"
            searchData={searchData}
            setCatalog={setCatalog}
            initialCatalog={initialCatalog}
          />
          <Categories
            catalog={catalog}
            activeCategoryRef={activeCategoryRef}
            activeCategoryIndex={activeCategoryIndex}
          />
        </div>
      </div>
    </div>
  );
}
