import React, { useEffect, useState } from 'react'

interface ITextarea {
  name?: string;
  value: string;
  readOnly?: boolean;
  onChange: (value: string) => void,
  onFormat?: (value: string) => string | null,
  autoComplete?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  textareaClassName?: string;
  errorLabel?: boolean,
  errors?: any,
  inPopup?: boolean
}

export default function  Textarea({
  name='',
  value,
  readOnly = false,
  onChange=()=>{},
  autoComplete = 'off',
  className = '',
  placeholder = '',
  maxLength,
  textareaClassName = '',
  errorLabel = true,
  onFormat,
  errors = {},
  inPopup = false
}: ITextarea) {
  const [enteredSymbols, setEnteredSymbols] = useState(0);

  const error = errors[name]; 

  useEffect(() => {
    delete errors[name];
  }, [value]);

  return (
    <div className={`${className} `}>
      <textarea
        maxLength={maxLength}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={e => {
          let val: string | null = e.target.value || '';
          if (onFormat) val = onFormat(val);
          onChange(val != null ? val : value);
          setEnteredSymbols(e.target.value.length)
        }}
        readOnly={readOnly}
        style={{outlineStyle: 'none'}}
        className={`${error && "border-[1px] border-yellow"} h-[135px] resize-none px-[15px] py-[9px] outline-0 disabled:pointer-events-none disabled:opacity-50 ${inPopup ? "mb-[10px]" : "mb-1"} w-full rounded-[10px] bg-gray-20 text-sm dark:bg-gray-40 dark:[&::-webkit-input-placeholder]:text-white ${textareaClassName}`}
        autoComplete={autoComplete}
      ></textarea>
      {error && errorLabel && (
        <span className={"text-xs font-medium text-yellow"}>{error}</span>
      )}
      {
        !!maxLength && 
        <p
          className={`text-end text-xs text-gray-30 xs:text-sm ${inPopup ? "mb-5" : "mb-[15px] mt-[6px] md:mb-[10px] md:mt-0"}`}
        >
          {enteredSymbols} / {maxLength}
        </p>
      }
    </div>
  )
}
