import React from 'react'
import { useAppContext } from '../../contexts/App'
import { Link, useLocation } from 'react-router-dom';


export default function Nav() {
  const { city } = useAppContext()
  let location = useLocation();

  return (
    <div className={"hidden gap-[25px] pb-5 pt-5 text-xs font-medium text-gray-60 dark:text-gray-10 md:flex"}>
      {
        items.map(item => (
          <Link
            className={`${
              location.pathname === `/${city.slug}${item.url}`
                ? 'relative text-main after:absolute after:bottom-[-3px] after:left-0 after:h-[2px] after:w-full after:rounded-full after:bg-main after:content-[""] dark:text-white'
                : "hover:text-main dark:hover:text-main"
            }`} 
            to={`/${city.slug}${item.url}`} 
            key={item.url}>{ item.label }
          </Link>
        ))
      }
    </div>
  )
}


const items = [
  {
    url: '',
    label: 'Каталог'
  },
  {
    url: '/stock',
    label: 'Акции'
  },
  {
    url: '/about',
    label: 'О нас'
  },
  {
    url: '/reviews',
    label: 'Отзывы'
  },
  {
    url: '/payment',
    label: 'Доставка и оплата'
  },
]