import StarIcon from "../../icons/StarIcon";
import { useEffect, useState } from "react";

interface IGood {
  goodId: number;
  estimation: number;
}

interface StarRatingProps {
  fillRate?: boolean;
  rate?: number;
  fieldName?: any;
  updateField?: any;
  errors?: any;
  errorLabel?: boolean;
  goodId?: number;
  goodsReviews?: IGood[] | any;
  distanceBetweenElements?: number;
  height?: number;
  width?: number;
  count?: number;
}

export default function StarRating({
  fillRate = false,
  rate,
  fieldName,
  updateField,
  errors = {},
  errorLabel = true,
  goodId,
  goodsReviews,
  distanceBetweenElements = 20,
  height = 35,
  width = 35,
  count = 5
}: StarRatingProps) {
  const [rating, setRating] = useState<any>(null);
  const [hover, setHover] = useState<any>(null);
  const [error, setError] = useState(() => {
    if (errors && fieldName) {
      return errors[fieldName];
    }
    return undefined;
  });

  useEffect(() => {
    if (fieldName && updateField) {
      updateField(fieldName, 0);
    }
  }, []);

  useEffect(() => {
    if (errors) {
      if (goodsReviews && goodId)
        setError(
          goodsReviews.map((answer: any, index: number) => {
            if (errors[`goodsReviews.${index}.estimation`]) {
              return errors[`goodsReviews.${index}.estimation`];
            }
          })
        );
    }
  }, [errors]);

  useEffect(() => {
    if (errors && fieldName) {
      delete errors[fieldName];
    }
  }, [rating, errors, fieldName]);

  function onChangeMealRating(rating: number) {
    setRating(rating);
    if (updateField) {
      updateField(fieldName, rating);
    }

    if (goodsReviews && goodId) {
      const currentReview = goodsReviews.find(
        (good: IGood) => good.goodId === goodId
      );
      if (currentReview) {
        currentReview.estimation = rating;
      }
    }
  }

  return (
    <>
      {fillRate ? (
        <div className="flex gap-x-[10px]">
          {[...Array(count)].map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index}>
                <StarIcon
                  className="h-[18px] w-[18px]"
                  colorClassName={`${rate && ratingValue <= rate ? "fill-main" : "fill-gray-30"}`}
                  height={height}
                  width={width}
                />
              </label>
            );
          })}
        </div>
      ) : (
        <div className={`flex gap-x-[${distanceBetweenElements}px] items-end`}>
          {[...Array(count)].map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index}>
                <input
                  className="hidden"
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => onChangeMealRating(ratingValue)}
                />
                <StarIcon
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                  className="cursor-pointer"
                  colorClassName={`${ratingValue <= (hover || rating) ? "fill-main" : "fill-gray-30"}`}
                  height={height}
                  width={width}
                />
              </label>
            );
          })}
        </div>
      )}
      {error && errorLabel && (
        <span className="text-xs font-medium text-yellow">{error}</span>
      )}
    </>
  );
}
